import React from "react";
import Img from "../../assets/about-us/Group 42-min.png";
// import t1 from "../../assets/about-us/team1.png";
// import t2 from "../../assets/about-us/tream 2.png";
// import t3 from "../../assets/about-us/team 3.png";
// import footer from "../../assets/about-us/Group 39.png";
import plus from "../../assets/about-us/element.png";
import { Link } from "react-router-dom";


export const about = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div>
        <div className="flex  justify-between md:flex-row flex-col mt-10 md:px-6 xl:p-3 lg:p-10">
          {/* section-1-start-here */}
          <div className=" md:w-[50%] flex  justify-center items-center   xl:text-[40px] text-xl self-center">
            <div className="flex flex-col xl:gap-4">
              <span className="text-[#000000] "> We build bridges </span>{" "}
              <span className="text-[#000000] ">
                {" "}
                between <span className="text-[#886CC0]">Companies and</span>
              </span>{" "}
              <span className="text-[#886CC0]"> Customer</span>
            </div>
          </div>
          {/* section-1-end-here */}
          {/* section-2-start-here */}

          <div className="md:w-[50%]   xl:px-10   flex  justify-center items-start p-10 text-[16px]  text-[#00000072] self-center">
            We take pride in our commitment to providing timely, cost-effective,
            and hassle-free research solutions. By partnering with clients
            across the globe , we ensure the utilization of optimal research
            methodologies to generate reliable and trustworthy data. Our
            unwavering dedication to meeting your research requirements instills
            confidence in the knowledge that your research needs are in capable
            hand
          </div>
          {/* section-2-end-here */}
        </div>
        {/* hero-section-start-here */}
        <img src={Img} className="min-w-full" />
        {/* hero-section-end-here */}
        {/* section-3-start-here */}
        <div className="flex text-center text-gray-500 xl:px-[150px] px-[50px] py-14">
          Trayistats is a premium, full-service online market research company
          that specializes in providing top-notch online research solutions to
          its clients. We offer a wide range of services that includes
          quantitative data collection, survey programming, and data analysis.
          With decades of experience in the industry, we’ve become a trusted
          name among businesses across the globe.
          <br /> One of our key strengths is our expertise in global
          quantitative data collection solutions. We’ve built a robust network
          of panelists and have reach in 30+ countries, and we’re capable of
          finding easy and hard-to-reach consumer, healthcare, and B2B survey
          panels. In addition, our online surveys are carefully programmed to
          ensure the accuracy and reliability of data. All of our clients
          understand that their research projects will be executed with
          precision and efficiency, delivering insights that can inform critical
          business decisions.
        </div>{" "}
        {/* section-3-end-here */}
        {/* section-4-start-here */}
        <div>
          <img
            src={plus}
            alt=" image"
            className="absolute right-0 mr-10 mt-4"
          />
          {/* <div className="w-full text-center">
            <div className="w-10/12 inline-block">
              <h1 className="text-[#886CC0] text-5xl font-bold lg:mt-20 sm:mt-20 md:mt-20 mt-40 text-start">
                <span className="text-[#202020]">Meet</span> Our Team
              </h1>

              <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5 text-start mt-12 mb-10">
                <!--Card 1-->
                <div>
                  <div class="rounded-md w-full">
                    <img class="" src={t1} alt="in-depth" />
                  </div>
                  <h6 className="text-xl font-bold mt-2">Shweta Shukla</h6>
                  <p>-Manager of Trayistats</p>
                </div>
                <!--Card 2-->
                <div>
                  <div class="rounded-md w-full">
                    <img class="" src={t2} alt="in-depth" />
                  </div>
                  <h6 className="text-xl font-bold mt-2">Shweta Shukla</h6>
                  <p>-Manager of Trayistats</p>
                </div>

                <!--Card 3-->
                <div>
                  <div class="rounded-md w-full">
                    <img class="" src={t3} alt="in-depth" />
                  </div>
                  <h6 className="text-xl font-bold mt-2">Shweta Shukla</h6>
                  <p className="text-md">-Manager of Trayistats</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* section-4-end-here */}
        {/* section-5-start-here */}
        <div className="bg-footer w-full h-[350px] flex justify-center items-center flex-col gap-10 mt-28">
          <h1 className="text-xs sm:text-[15]  md:text-[20px] xl:text-[40px] text-center text-white font-semibold  break-normal min-w-fit whitespace-nowrap">
            Let’s Connect and make your dream true together
          </h1>
          <button className="bg-[#886CC0] text-white hover:bg-[#fff] w-[80px] sm:w-[120px] lg:w-[160px] md:w-[140px] rounded-md font-medium py-1 lg:py-2 md:py-2 sm:py-2  hover:text-[#886CC0] ">
                  <Link to='/contact' onClick={handleClick}>Contact Us</Link>
                </button>
        </div>
        {/* section-5-end-here */}
      </div>
    </>
  );
};
export default about;
