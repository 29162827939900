import React from "react";
import terms from "../../assets/panel/term .png";

function Terms() {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={terms} alt="banner" />
        <h1 className="absolute text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          Terms & Conditions
        </h1>
      </div>
      <div className="w-full text-center mb-16 mt-16">
        <div className="w-11/12 inline-block text-start">
          <h1 className="font-bold text-xl">Terms & Conditions</h1>
          <p className="mt-2">
            Employees, agents, and affiliates for any direct, indirect,
            incidental, consequential, special, or exemplary damages arising out
            of or in connection with your use of the Sites or the inability to
            use the Sites, including but not limited to damages for loss of
            profits, data, or other intangible losses.
          </p>
          <p className="mt-2">
            Indemnification You agree to indemnify, defend, and hold harmless
            <b> Trayistats AI Technologies ,</b> its directors, officers, employees,
            agents, and affiliates from and against any and all claims, damages,
            liabilities, costs, and expenses, including reasonable attorneys'
            fees and court costs, arising out of or in connection with your use
            of the Sites, your breach of these General Terms, or your violation
            of any rights of a third party.
          </p>
          <p className="mt-2">
            Termination We may terminate our contract with you and your access
            to the Sites at any time and for any reason, without prior notice.
            Upon termination, you must immediately cease all use of the Sites
            and destroy any materials obtained from the Sites.
          </p>
          <p className="mt-2">
            Governing Law and Jurisdiction These General Terms shall be governed
            by and construed in accordance with the laws of the province of
            Noida, India without regard to its conflict of laws principles. Any
            dispute arising out of or in connection with these General Terms or
            the use of the Sites shall be submitted to the exclusive
            jurisdiction of the courts located in the province of Noida, India.
          </p>
          <p className="mt-2">
            Entire Agreement These General Terms constitute the entire agreement
            between you and <b>Trayistats AI Technologies .</b> regarding your use
            of the Sites and supersede any prior or contemporaneous agreements,
            communications, or representations, whether oral or written,
            relating to the subject matter herein.
          </p>
          <p className="mt-2">
            Severability If any provision of these General Terms is found to be
            invalid, illegal, or unenforceable, the remaining provisions shall
            continue in full force and effect.
          </p>
          <p className="mt-2">
            Waiver Our failure to enforce any provision of these General Terms
            shall not be deemed a waiver of such provision or any other
            provision.
          </p>
          <p className="mt-2">
            Contact If you have any questions, concerns, or complaints regarding
            these General Terms or the Sites, please refer to our Privacy Policy
            or contact us at the provided address or email.
          </p>
          <p className="mt-2">
            Please note that the above text is a fictional representation of
            terms and conditions and should not be considered legally binding.
            It is always advisable to consult the actual terms and conditions
            provided by the respective company or website.
          </p>
          <div className="text-start">
            <p className="mt-5">
              These Terms govern the use of the website and any other related
              agreements or legal relationships with the owner, <b>Trayistats AI
              Technologies .</b> The terms outline the rights and
              responsibilities of users when using the website and its services.
              Here are some key points from the Terms and Conditions:
            </p>
            <ul className="list-decimal">
              <li className="mt-2 ml-4">
                <p>
                  <b>User Account: </b>Users must register and provide accurate
                  information to access the services. They are responsible for
                  maintaining the confidentiality of their account credentials
                  and must inform the owner if there is any unauthorized access.
                </p>
              </li>
              <li className="mt-2 ml-4">
                <p>
                  <b>Account Termination: </b>Account Termination: Users can
                  terminate their accounts by contacting the owner directly.
                </p>
              </li>
              <li className="mt-2 ml-4">
                <p>
                  <b>Content: </b>The content on the website is owned or provided by the owner and its licensors. Users are prohibited from using the content in any way that goes beyond the scope of the service.
                </p>
              </li>
              <li className="mt-2 ml-4">
                <p>
                  <b>External Resources: </b>The website may provide access to external resources provided by third parties. The owner is not responsible for the content and availability of these external resources.
                </p>
              </li>
              <li className="mt-2 ml-4">
                <p>
                  <b>Acceptable Use: </b>Users must use the website and service within the limits of applicable laws and regulations. The owner reserves the right to deny access and take appropriate measures if users engage in activities that violate laws, infringe rights, or harm the owner or others.
                </p>
              </li>
              <li className="mt-2 ml-4">
                <p>
                  <b>API Usage: </b>Users may access their data through the Application Program Interface (API), subject to the terms specified. The owner is not liable for any damages resulting from the user's use of the API or third-party products/services accessing data through the API.
                </p>
              </li>
              <li className="mt-2 ml-4">
                <p>
                  <b>Terms and Conditions of Sale: </b>Some products on the website may be provided on a paid basis. The terms, prices, and purchasing process for these products are described on the website.
                </p>
              </li>
              <li className="mt-2 ml-4">
                <p>
                  <b>Right of Withdrawal: </b>European consumers may have the right to withdraw from a contract within a specified period. Users can exercise this right by notifying the owner within the specified timeframe.
                </p>
              </li>
              <li className="mt-2 ml-4">
                <p>
                  <b>Liability and Indemnification: </b>The Terms include limitations of liability, which may vary depending on the user's jurisdiction.
                </p>
              </li>
              <li className="mt-2 ml-4">
                <p>
                  <b>Disclaimer of Warranties: </b>The website and service are provided "as is," and the owner does not guarantee the accuracy, availability, or suitability of the content. Users download content at their own risk.
                </p>
              </li>
              <li className="mt-2 ml-4">
                <p>
                  <b>Limitations of Liability: </b>The owner and its affiliates are not liable for indirect, incidental, or consequential damages arising from the use of the service.
                </p>
              </li>
            </ul>
            <p className="mt-6">
            These are some of the key points from the Terms and Conditions of <b>Trayistats AI Technologies .</b> Users are advised to read the complete document carefully before using the website and its services.
          </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
