import { useEffect } from "react";

export function useOutsideClick(ref, onClickOut) {
  useEffect(() => {
    const onClick = ({ target }) => {
      if (ref.current && ref.current !== target && !ref.current.contains(target)) {
        onClickOut();
      }
    };

    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, [ref, onClickOut]);
}
