import React from 'react'
import { Link } from 'react-router-dom'
import banner from '../../../assets/panel/healthcare.png'
import pic2 from '../../../assets/panel/pic2.png'
import diabetes from '../../../assets/panel/diabetes.png'
import copd from '../../../assets/panel/copd.png'
import orthopedic from '../../../assets/panel/orthopedic.png'
import gynecologist from '../../../assets/panel/gynecologist.png'
import cancer from '../../../assets/panel/cancer.png'
import background from '../../../assets/panel/background.png'


function HealthPanel() {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
    <div className="w-full flex items-center justify-center">
      <img className="h-auto w-full" src={banner} alt='banner' />
      <h1 className="absolute text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
        Healthcare Panel
      </h1>
    </div>
    <div className="w-full text-center bg-[#F5F5F6]">
      <div className="w-11/12 inline-block">
        <div className="flex justify-center mt-10">
          <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
            <div className="md:w-1/2 flex justify-center">
              <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start p-4">
                <p className="text-4xl font-bold text-black pb-4 mt-4">Powering Your Healthcare Market Research Journey</p>
                <p className="text-black mb-10 text-gray-700">
                Discover a wealth of healthcare market research opportunities with Trayistats AI Technologies. Our dedicated team specializes in providing the data and insights you need to uncover new perspectives. Tap into our healthcare panel, comprising deeply profiled patients, caregivers, doctors, and medical professionals. Leverage our vast collection of documented attributes to precisely target your research needs. We prioritize building trusted relationships with our panelists, allowing us to quickly gather demographic information tailored to your unique requirements. Gain invaluable insights and make informed decisions with Trayistats AI Technologies  trusted healthcare market research team.
                </p>
              </div>
            </div>
            <div className="md:w-1/2 flex justify-center">
              <img src={pic2} alt="who we are" className="mt-5 lg:h-80 md:h-80 mb-5 " />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full text-center mb-16 mt-16">
      <div className="w-11/12 inline-block">
        <h1 className='lg:text-5xl md:text-5xl sm:text-4xl xs:text-3xl text-2xl font-bold text-[#886CC0]'>Healthcare/Caregiver</h1>

        <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5 text-start mt-5">
          {/* <!--Card 1--> */}
          <div className="border rounded-xl overflow-hidden shadow-lg bg-white z-10">
            <div className="px-6 py-4 text-center">
              <img className="w-20 mx-auto" src={diabetes} alt="demograph" />
              <p className="font-bold text-xl mb-2 mt-4">Diabetes</p>
            </div>
          </div>
          {/* <!--Card 2--> */}
          <div className="border rounded-xl overflow-hidden shadow-lg bg-white z-10">
            <div className="px-6 py-4 mt-2 text-center">
              <img className="w-20 mx-auto" src={copd} alt="psychgraph" />
              <p className="font-bold text-xl mb-2 mt-4">COPD</p>
            </div>
          </div>

          {/* <!--Card 3--> */}
          <div className="border rounded-xl overflow-hidden shadow-lg bg-white z-10">
            <div className="px-6 py-4 mt-2 text-center">
              <img className="w-20 mx-auto" src={orthopedic} alt="geograph" />
              <p className="font-bold text-xl mb-2 mt-4">Orthopedic</p>
            </div>
          </div>
        </div>
        {/* <div className='w-full text-center'> */}
        <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5 text-start mt-5">
          {/* <!--Card 1--> */}
          <div className="border rounded-xl overflow-hidden shadow-lg bg-white">
            <div className="px-6 py-4 text-center">
              <img className="w-20 mx-auto" src={gynecologist} alt="behavioral" />
              <div className="font-bold text-xl mb-2 mt-4">Gynecologist</div>
            </div>
          </div>

          {/* <!--Card 2--> */}
          <div className="border rounded-xl overflow-hidden shadow-lg bg-white z-10">
            <div className="px-6 py-4 mt-2 text-center">
              <img className="w-20 mx-auto" src={cancer} alt="technograph" />
              <p className="font-bold text-xl mb-2 mt-4">Cancer</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* </div> */}
    <div className="relative w-full justify-center">
      <div className="relative">
        <img src={background} alt="" className="w-full h-40 lg:h-96 md:h-96" />
      </div>
      <div className="flex justify-center items-center">
        <div className="absolute w-full lg:w-4/5 md:w-2/3 sm:w-2/3 justify-end h-full justify-center items-center lg:mb-10 md:mb-20 sm:mb-10 mb-16">
          <p className="text-center text-xs lg:text-3xl md:text-2xl sm:text-lg text-md font-semibold leading-7 md:w-full">
            <span className="text-white text-center">Let's Connect and make your dream true together</span>
          </p>
          <div className="justify-center mt-2 lg:mt-5 md:mt-5 text-center transition ease-in-out">
                <button className="bg-[#886CC0] text-white hover:bg-[#fff] w-[80px] lg:w-[160px] md:w-[140px] rounded-md font-medium py-1 lg:py-2 md:py-2 sm:py-2  hover:text-[#886CC0] ">
                  <Link to='/contact' onClick={handleClick}>Contact Us</Link>
                </button>
              </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default HealthPanel