import React from "react";
import phone from "../../assets/service/phone-web.png";
import quick from "../../assets/service/quick.svg";
import custom from "../../assets/service/customize.svg";
import support from "../../assets/service/responsive.svg";
import expertise from "../../assets/service/expertise.svg";
import speciality from "../../assets/service/specialty.svg";
import profiling from "../../assets/service/profiling.svg";
import rapid from "../../assets/service/rapid.svg";
import device from "../../assets/service/device.svg";


function Topline() {
  return (
    <div>
      <div className="w-full text-center mb-16 sm:mt-24 mt-4">
        <div className="w-11/12 inline-block text-center" id="/top-line">
          <h1 className="font-bold text-4xl text-[#474747]">
            Top-line Reports/ Charts & Pivots
          </h1>
          <p className="mt-4">
            At Trayistats AI Technologies LLC, we understand the importance of
            providing actionable insights to our clients. As a value-added
            extra, we offer the production of a comprehensive Top-Line Report.
            This report serves as a concise yet powerful tool that presents data
            in clear charts and tables, making it easier for our clients to
            interpret and understand their research findings.
          </p>
          <p className="mt-4">
            The Top-Line Report goes beyond raw data and transforms it into a
            visually appealing format that highlights key trends, patterns, and
            insights. Our skilled team customizes the report to meet the
            specific requirements of each client, ensuring that the most
            relevant information is presented in a clear and intuitive manner.By
            leveraging visual representations such as charts, graphs, and
            tables, the Top-Line Report enables our clients to grasp complex
            data at a glance. It simplifies the process of data analysis and
            empowers our clients to make informed decisions based on a
            comprehensive understanding of their research results.
          </p>
          <p className="mt-4">
            We recognize that every client has unique reporting needs, and our
            Top-Line Report is designed to be customizable. Our team works
            closely with clients to tailor the report's format, content, and
            visualizations to align with their preferences and objectives.
            Whether it's a summary of overall findings, a comparison of
            different segments, or the identification of key insights, the
            Top-Line Report provides a concise and impactful snapshot of the
            research outcomes.At Trayistats AI Technologies LLC, we believe that
            data becomes truly valuable when it is translated into actionable
            insights. Through our Top-Line Report, we aim to empower our clients
            with a clear understanding of their data, enabling them to make
            informed decisions and drive positive outcomes.
          </p>
        </div>
      </div>
      <div className="w-full text-center mb-16 mt-16">
        <div className="w-11/12 inline-block text-center">
          <h1 className="font-bold text-4xl text-[#474747]">
            Phone2Web Via Screen Access
          </h1>
          <p className="mt-4">
            The Phone2Web Via Screen Access service by Trayistats AI
            Technologies LLC enables quick problem resolution during the survey
            process. While respondents navigate through the survey on their own,
            the interviewer remains on mute mode on the phone but is readily
            available to provide assistance if needed. This ensures that any
            technical difficulties or confusion can be promptly addressed,
            minimizing disruptions and ensuring a smooth survey experience.
          </p>
          <p className="mt-4">
            By promptly resolving issues, Trayistats AI Technologies LLC
            demonstrates its commitment to customer satisfaction and efficient
            data collection. The combination of self-guided survey completion
            and on-demand support creates a seamless and user-friendly
            experience for respondents.
          </p>
          <p className="mt-4">
            Furthermore, the ability to address problems in real-time helps
            maintain the momentum of the survey and prevents potential
            frustrations or dropouts. Trayistats AI Technologies LLC 's emphasis
            on providing reliable technical support contributes to a positive
            respondent experience and increases the likelihood of obtaining
            accurate and comprehensive survey responses.
          </p>
          <p className="mt-4">
            Overall, the Phone2Web Via Screen Access service not only
            facilitates efficient data collection but also prioritizes
            respondent satisfaction by ensuring that any obstacles or questions
            are swiftly addressed. Trayistats AI Technologies LLC 's commitment
            to excellent customer service is reflected in this innovative
            approach to problem resolution during the survey process.
          </p>
        </div>
      </div>
      <div className="w-full text-center mb-16 mt-16 bg-[#F5F5F5]">
        <div className="w-11/12 inline-block text-start">
          <div className="flex flex-col text-white w-full my-12">
            <div className="flex flex-col-reverse font-sans mx-auto items-center w-full gap-6 mx-auto md:flex-row md:gap-0">
              <div className="w-full md:w-full">
                <div className="">
                  <p className="lg:w-3/4 md:w-full w-full leading-[2rem] md:mt-4 text-[2rem] md:text-[3rem] text-[#484848] font-semibold md:text-left">
                    Features
                  </p>
                  <ul className="mt-8">
                    <li>
                      <img src={quick} alt="" className="absolute" />
                      <p className="ml-10 mt-3 text-black">
                        Real-time collaboration
                      </p>
                    </li>
                    <li>
                      <img src={device} alt="" className="absolute" />
                      <p className="ml-10 mt-3 text-black">
                        Multi-device compatibility
                      </p>
                    </li>
                    <li>
                      <img src={speciality} alt="" className="absolute" />
                      <p className="ml-10 mt-3 text-black">
                        Advanced survey functionalities
                      </p>
                    </li>
                    <li>
                      <img src={custom} alt="" className="absolute" />
                      <p className="ml-10 mt-3 text-black">
                        Enhanced data collection capabilities
                      </p>
                    </li>
                    <li>
                      <img src={expertise} alt="" className="absolute" />
                      <p className="ml-10 mt-3 text-black">
                        Real-time data synchronization
                      </p>
                    </li>
                    <li>
                      <img src={rapid} alt="" className="absolute" />
                      <p className="ml-10 mt-3 text-black">
                        Analytics and reporting
                      </p>
                    </li>
                    <li>
                      <img src={support} alt="" className="absolute" />
                      <p className="ml-10 mt-3 text-black">
                        Multi-language support
                      </p>
                    </li>
                    <li>
                      <img src={profiling} alt="" className="absolute" />
                      <p className="ml-10 mt-3 text-black">
                        Seamless integration with existing systems
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <img src={phone} alt="" className="h-full md:h-64" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topline;
