import React from "react";
import { NavLink,Link } from "react-router-dom";
// import { Link } from "react-router-hash-link";
import card1 from "../../assets/home/card1.png";
import card2 from "../../assets/home/card2.png";
import card3 from "../../assets/home/card3.png";
import card4 from "../../assets/home/card4.png";
import card5 from "../../assets/home/card5.png";
import card6 from "../../assets/home/card6.png";
import card7 from "../../assets/home/card7.png";
import card8 from "../../assets/home/card8.png";
import card9 from "../../assets/home/card9.png";

export default function ServiceContainer() {
  return (
    <div className="left-0 top-[80px] sm:mt-20 w-full h-auto z-50">
      <div className="w-full relative bg-[#FFFFFF] text-center overflow-x-auto">
        <div className="w-11/12  inline-block overflow-x-auto">
          <ul className="list-none flex flex-wrap justify-start pb-10">
            <li
              className="flex items-start sm:w-1/2 lg:w-1/3 text-start w-full my-4"
              
            >
              <img src={card1} alt="data-service" className="w-10 pt-2" />
              <div className="flex flex-col">
                <a
                 href="/data-services">
                  <p className="w-auto text-xl text-[#474747] pl-3">
                    Data Services : Data visualization & Dashboard
                  </p>
                  
                </a>

                <p className="text-[#969696] pl-3 text-sm pt-1">
                  Data1 Visualization & Dashboard service goes beyond creating
                  visually appealing representations of data.
                </p>
              </div>
            </li>
            <li
              className="flex items-start sm:w-1/2 lg:w-1/3 text-start w-full my-4"
             
            >
              <img src={card2} alt="market-research" className="w-10 pt-2" />
              <div className="flex flex-col">
                <a href="/computer-assisted">
                  <p className="text-xl text-[#474747] pl-3">
                    Computer-Assisted Web Interviewing (CATI, Online)
                  </p>
                </a>
                <p className="text-[#969696] pl-3 text-sm pt-1">
                  It leverages a network of dedicated panel partners that have
                  the capability to serve 40 locations and regions
                </p>
              </div>
            </li>
            <li
              className=" flex items-start sm:w-1/2 lg:w-1/3 text-start w-full my-4"
             
            >
              <img src={card3} alt="in-depth" className="w-10 pt-2" />
              <div className="flex flex-col">
                <a href="/in-depth">
                  <p className="text-xl text-[#886CC0] pl-3">
                    In-Depth Interviewing (IDI)
                  </p>
                </a>
                <p className="text-[#969696] pl-3 text-sm pt-1">
                  We firmly believe that conducting interviews is a delicate art
                  that requires a unique set of skills and qualities
                </p>
              </div>
            </li>
            <li className=" flex items-start sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img src={card4} alt="panel-management" className="w-10 pt-2" />
              <div className="flex col-flex">
                <div className="flex flex-col">
                  <a href="/panel-management">
                    <p className="text-xl text-[#474747] pl-3">
                      Panel management
                    </p>
                  </a>
                  <p className="text-[#969696] pl-3 text-sm pt-1">
                    Our specialty panel consists of a vast network of 400,000 IT
                    Decision Makers and nearly 15 million unique consumers,
                    spread across 40 countries.
                  </p>
                </div>
              </div>
            </li>
            <li className=" flex items-start sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img
                src={card5}
                alt="secondary-research"
                className="w-10 pt-2"
              />
              <div className="flex flex-col">
                <a href="/secondary-research">
                  <p className="text-xl text-[#474747] pl-3">
                    Secondary Research
                  </p>
                </a>
                <p className="text-[#969696] pl-3 text-sm pt-1">
                  While our primary research remains our primary focus, we
                  understand the value of secondary research in providing
                  comprehensive insights
                </p>
              </div>
            </li>
            <li className=" flex items-start sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img src={card6} alt="top-line" className="w-10 pt-2" />
              <div className="flex flex-col">
                {" "}
                <a href="/top-line">
                  <p className="text-xl text-[#474747] pl-3">
                    Top-line Reports/ Charts & Pivots
                  </p>
                </a>
                <p className="text-[#969696] pl-3 text-sm pt-1">
                  As a value-added extra, we offer the production of a
                  comprehensive Top-Line Report.
                </p>
              </div>
            </li>
            <li className=" flex items-start sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img src={card7} alt="#phone" className="w-10 pt-2" />
              <div className="flex flex-col">
                <a href="/phone">
                  <p className="text-xl text-[#474747] pl-3">
                    Phone2Web Via Screen Access
                  </p>
                </a>
                <p className="text-[#969696] pl-3 text-sm pt-1">
                  While respondents navigate through the survey on their own,
                  the interviewer remains on mute mode on the phone but is
                  readily a
                </p>
              </div>
            </li>
            <li className=" flex items-start sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img
                src={card8}
                alt="computer-assisted"
                className="w-10 pt-2"/>
             <div className="flex flex-col"> 
             <a href="/computer-assisted">
                <p className="text-xl text-[#474747] pl-3">
                  Computer-Assisted Telephone Interviewing (CATI)
                </p>
              </a>
              <p className="text-[#969696] pl-3 text-sm pt-1">
                CATI services, which have been refined and improved over many
                years of experience
              </p></div>
            </li>
            <li className=" flex items-start sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img
                src={card9}
                alt="survey-programming"
                className="w-10 pt-2"/>
              <div className="flex flex-col">
                <a href="/survey-programming">
                <p className="text-xl text-[#474747] pl-3">
                  Survey Programming & Hosting
                </p>
              </a>
              <p className="text-[#969696] pl-3 text-sm pt-1">
                Survey Programming service is led by a highly capable and
                qualified team proficient in the latest market research survey
                tools across multiple platforms
              </p></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
   
  );
}
