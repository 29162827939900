import React from 'react'
import speciality from "../../assets/service/specialty.svg";
import customizes from "../../assets/service/customizes.svg";
import rapid from "../../assets/service/rapid.svg";
import expertise from "../../assets/service/expertise.svg";
import profiling from "../../assets/service/profiling.svg";
import expert from "../../assets/service/expert.svg";
import survey from "../../assets/service/survey.png"


function Surveyprogramming() {
  return (
    <div> <div className="w-full text-center mb-16 sm:mt-24 mt-4">
    <div
      className="w-11/12 inline-block text-center"
      id="survey-programming"
    >
      <h1 className="font-bold text-4xl text-[#474747]">
        Survey Programming & Hosting
      </h1>
      <p className="mt-4">
        At Trayistats AI Technologies LLC, our Survey Programming service
        is led by a highly capable and qualified team proficient in the
        latest market research survey tools across multiple platforms. Our
        expertise extends to popular platforms such as Decipher,
        Qualtrics, ConfirmIT, Sawtooth Technologies, and various other
        industry-standard research tools. We are well-equipped to handle
        diverse survey programming requirements using these tools.
      </p>
      <p className="mt-4">
        In addition to leveraging third-party survey platforms, Trayistats
        AI Technologies LLC has developed its own proprietary survey
        program called MyEdge. This customized solution integrates
        seamlessly with our Management Information Systems, enabling us to
        consistently deliver exceptional results to our clients. MyEdge
        offers advanced functionality and flexibility to tailor surveys
        precisely to our clients' needs.
      </p>
      <p className="mt-4">
        We understand that data compatibility is crucial for our clients,
        which is why we offer versatile data export options. The data
        collected through our surveys can be exported in widely used
        formats such as SPSS, Excel, ASCII, and more. Furthermore, we are
        capable of converting the data into almost any requested format,
        ensuring compatibility with our clients' specific data analysis
        systems and processes.At Trayistats AI Technologies LLC, our
        Survey Programming capabilities are designed to effectively handle
        complex data while ensuring a smooth and streamlined process. Our
        team's expertise in utilizing market research survey tools,
        including our proprietary solution, combined with our commitment
        to data compatibility, enables us to deliver high-quality survey
        programming services that meet the unique requirements of each
        client.
      </p>
    </div>
  </div>
  <div className="w-full text-center mb-16 mt-16 bg-[#F5F5F5]">
    <div className="w-11/12 inline-block text-start">
      <div className="flex flex-col text-white w-full my-12">
        <div className="flex flex-col-reverse font-sans mx-auto items-center w-full gap-6 mx-auto md:flex-row md:gap-0">
          <div className="w-full md:w-full">
            <div className="">
              <p className="lg:w-3/4 md:w-full w-full leading-[2rem] md:mt-4 text-[2rem] md:text-[3rem] text-[#484848] font-semibold md:text-left">
                Features
              </p>
              <ul className="mt-8">
                <li>
                  <img src={speciality} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Highly Skilled Team
                  </p>
                </li>
                <li>
                  <img src={customizes} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Customized Survey Tool – MyEdge
                  </p>
                </li>
                <li>
                  <img src={expert} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Experience with Industry-Standard Research Tools
                  </p>
                </li>
                <li>
                  <img src={rapid} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Versatile Data Export Options
                  </p>
                </li>
                <li>
                  <img src={profiling} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Quality Control Measures
                  </p>
                </li>
                <li>
                  <img src={expertise} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Collaborative Approach
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <img src={survey} alt="" className="h-full md:h-64" />
        </div>
      </div>
    </div>
  </div></div>
  )
}

export default Surveyprogramming