import React from "react";
import contact1 from "../../assets/contact-us/contact1.png";
import mobile from "../../assets/contact-us/mobile.svg";
import mail from "../../assets/contact-us/mail.svg";
import location from "../../assets/contact-us/location.svg";
import fb from "../../assets/contact-us/fb.svg";
import insta from "../../assets/contact-us/insta.svg";
import linkedin from "../../assets/contact-us/linkedin.svg";
import { Link } from "react-router-dom";

function Contact() {
  return (
    <>
      <div>
        <div className="w-full text-center mt-2 sm:mt-24 mb-10">

          <div className="w-11/12 inline-block">
            <div className="flex justify-center mt-5">
              <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
                <div className="lg:w-1/2 md:w-1/2 sm:w-1/2 w-full">
                  <div className="grow lg:w-full w-full max-h-fit text-start">
                    <h1 className="text-5xl sm:text-6xl text-[#886CC0] font-bold w-full">
                      Contact Us
                    </h1>
                    <p className="mt-2 w-full">
                      Have questions? Contact us for assistance and support
                    </p>
                    <div className="w-full lg:w-9/12 md:w-11/12 mt-6 ">
                      <label className="block mb-2 text-black text-start">
                        Full Name
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Full Name"
                        className="block border border-grey-400 text-black focus:outline-none w-full px-3 py-2 text-sm text-start justify-start rounded-md"
                      />
                    </div>
                    <div className="flex gap-1 md:gap-10 w-full lg:w-9/12 md:w-11/12 my-5 item-center">
                      <div className="w-full">
                        <label className="block mb-2 text-black text-start">
                          Email Address
                        </label>
                        <input
                          type="email"
                          placeholder="Enter Email Address"
                          className="block border border-grey-400 text-black focus:outline-none w-full px-3 py-2 text-sm text-start justify-start rounded-md"
                        />
                      </div>
                      <div className="w-full">
                        <label className="block mb-2 text-black text-start">
                          Phone Number
                        </label>
                        <input
                          type="tel"
                          placeholder="Enter phone number"
                          className="block border border-grey-400 text-black focus:outline-none w-full px-3 py-2 text-sm text-start rounded-md"
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-9/12 md:w-11/12 my-5 item-center">
                      <div>
                        <label className="block mb-2 text-black">
                          Message ( Your enquiry )
                        </label>
                        <textarea
                          className="block border-grey-400 text-black focus:outline-none p-2.5 w-full border text-sm rounded-lg "
                          placeholder="Enter your Enquiry here"
                        ></textarea>
                      </div>
                    </div>
                    <div className="">
                      <button
                        type="submit"
                        className="bg-[#886CC0] h-10 text-lg text-white mb-5 lg:w-9/12 md:w-11/12 w-full rounded-md font-medium py-1 text-white"
                      >
                        Send message
                      </button>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="sm:w-1/2 justify-start">
                  <img
                    src={contact1}
                    alt="work-content"
                    className="w-10/12 mt-5"
                  />
                  <div>
                    <p className="flex items-center  mb-4 md:justify-start  text-[#8E8E8E]">
                      <img src={mobile} alt="mobile" className="mr-5" />
                      +91-9802474839
                    </p>
                    <p className="flex items-center  mb-4 md:justify-start  text-[#8E8E8E]">
                      <img src={mail} alt="mobile" className="mr-5" />
                      <Link to="https://sales@trayistats.com">
                        sales@trayistats.com
                      </Link>
                    </p>
                    <p className="flex items-center  mb-4 md:justify-start  text-[#8E8E8E]">
                      <img src={location} alt="address" className="mr-5 mt-[-30px]" />
                      <div className="flex-col">
                        <h3 className="text-left sm:text-left">Registered Office</h3>
                        <h3 className="text-left sm:text-left"> C/07/4,Centurian Park-Low Rise, Plot GH-05,
                          <h3 className="text-left sm:text-left">
                          Techzone-4, Greater Noida West, Gautam Budh Nagar 201306
                        </h3>
                        </h3></div>
                    </p>
                    <p className="flex items-center  mb-4 md:justify-start  text-[#8E8E8E]">
                      <img src={location} alt="address" className="mr-5" />
                      <div className="flex-col">
                        <h3 className="mr-52">  Corporate  Office</h3>
                        <h3> C-317, Cyber Park, Sector-62, Noida-201301</h3></div>
                    </p>
                    <div className="flex justify-end ml-auto mt-2">
                      {/* <img src={fb} alt="" className="mr-5 w-10" />
                      <img src={insta} alt="" className="mr-5 w-10" /> */}
                      <Link to='https://www.linkedin.com/company/98055371/admin/feed/posts/'>
                        <img src={linkedin} alt="" className="mr-5 w-10" />
                      </Link>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
