import React from 'react'
import depth from "../../assets/service/in-depth.png";
import exclusive from "../../assets/service/exclusive.svg";
import regular from "../../assets/service/regular.svg";
import expert from "../../assets/service/expert.svg";
import support from "../../assets/service/responsive.svg";

function Indepth() {
  return (
    <div><div className="w-full text-center mb-16 sm:mt-24 mt-4">
    <div className="w-11/12 inline-block text-center" id="in-depth">
      <h1 className="font-bold text-4xl text-[#886CC0]">
        In-Depth Interviewing (IDI)
      </h1>
      <p className="mt-4">
        At Trayistats AI Technologies, we firmly believe that conducting
        interviews is a delicate art that requires a unique set of skills
        and qualities. We understand that successful interviews require
        not only patience and listening skills but also in-depth knowledge
        of the subject matter. That's why our IDI (In-depth Interviewing)
        process is executed by a specialized team that undergoes rigorous
        training tailored specifically for this purpose.
      </p>
      <p className="mt-4">
        Our IDI team is distinct from the professionals involved in CATI
        operations. We ensure that interviewers possess a comprehensive
        understanding of the topic and have equal familiarity with the
        questionnaire. This ensures that they are well-prepared to conduct
        interviews with confidence and expertise. Additionally, it is
        crucial for interviewers to possess thorough knowledge about the
        subject matter and the respondents they will be engaging with.
      </p>
      <p className="mt-4">
        To further enhance the professionalism and quality of our
        interviews, Trayistats AI Technologies ensures that our
        interviewers are solely dedicated to conducting interviews. They
        are not involved in other quantitative tasks, allowing them to
        focus exclusively on delivering exceptional interview experiences.
        This singular focus allows our interviewers to approach each
        interview with the necessary concentration, attention to detail,
        and professionalism it deserves.
      </p>
      <p className="mt-4">
        In our IDI service, the art of patient listening takes precedence
        above all else. We understand the significance of actively
        listening to respondents to collect the most relevant and
        comprehensive information. Our interviewers are trained to create
        a comfortable and conducive environment that encourages
        respondents to share their thoughts openly. By patiently listening
        and capturing their insights, we ensure that valuable and nuanced
        data is gathered.
      </p>
      <p className="mt-4">
        At Trayistats AI Technologies, we view the art of interviewing as
        a crucial component of our research methodology. Our IDI service
        exemplifies our commitment to conducting interviews with
        professionalism, subject expertise, and unwavering attention to
        active listening. By upholding these principles, we ensure that
        our clients receive in-depth and valuable insights to drive their
        decision-making processes.
      </p>
    </div>
  </div>
  <div className="w-full text-center mb-16 mt-16 bg-[#F5F5F5]">
    <div className="w-11/12 inline-block text-start">
      <div className="flex flex-col text-white w-full my-12">
        <div className="flex flex-col-reverse font-sans items-center w-full gap-6 mx-auto md:flex-row md:gap-0">
          <div className="w-full md:w-full">
            <div className="">
              <p className="lg:w-3/4 md:w-full w-full leading-[2rem] md:mt-4 text-[2rem] md:text-[3rem] text-[#484848] font-semibold md:text-left">
                Features
              </p>
              <ul className="mt-8">
                <li>
                  <img src={expert} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Access to a panel of subject matter experts
                  </p>
                </li>
                <li>
                  <img src={support} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Team chosen based on subject expertise
                  </p>
                </li>
                <li>
                  <img src={exclusive} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Exclusive dedication to IDI process
                  </p>
                </li>
                <li>
                  <img src={regular} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Regular training for understanding respondents and
                    topics
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <img src={depth} alt="" className="h-96 md:h-64" />
        </div>
      </div>
    </div>
  </div></div>
  )
}

export default Indepth