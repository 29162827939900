import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Layout from "./pages/layout/Layout";
import Contact from "./components/contact us/Contact";
import Home from "./pages/home/Home";
import About from "./components/aboutus/about.js";
import Policy from "./components/privacy policy/Policy";
import ServiceContainer from "./components/header/ServiceContainer";
import B2BPanel from "./components/panel/b2b panel/B2BPanel";
import B2CPanel from "./components/panel/b2c panel/B2CPanel";
import HealthPanel from "./components/panel/health care/HealthPanel";
import Terms from "./components/footer/Terms";
import Dataservices from "./components/service/Dataservices";
import Indepth from "./components/service/Indepth";
import Panelmanagement from "./components/service/Panelmanagement";
import Computerassisted from "./components/service/Computerassisted";
import Computertelephonic from "./components/service/Computertelephonic";
import Secondaryresearch from "./components/service/Secondaryresearch";
import Surveyprogramming from "./components/service/Surveyprogramming";
import Topline from "./components/service/Topline";
import Phoneweb from "./components/service/Phoneweb";

export const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/services" element={<ServiceContainer />} />
          <Route path="/b2b-panel" element={<B2BPanel />} />
          <Route path="/b2c-panel" element={<B2CPanel />} />
          <Route path="/healthcare" element={<HealthPanel />} />
          <Route path="/terms" element={<Terms />} />
          <Route path ="/data-services" element={<Dataservices/>}/>
          <Route path="/in-depth" element={<Indepth/>}/>
          <Route path="/panel-management" element={<Panelmanagement/>}/>
          <Route path="/computer-assisted" element={<Computerassisted/>}/>
          <Route path="/computer-telephonic" element={<Computertelephonic/>}/>
          <Route path="/secondary-research" element={<Secondaryresearch/>}/>
          <Route path="/survey-programming" element={<Surveyprogramming/>}/>
          <Route path="/top-line" element={<Topline/>}/>
          <Route path="/phone" element={<Phoneweb/>}/>
        
          
        </Route>
      </Routes>
    </Router>
  );
};
