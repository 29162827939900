import React from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { Outlet } from 'react-router-dom'

function Layout() {
    return (
        <div>
            <header>
                <Header />
            </header>
            <main className='h-full'>
                <Outlet />
            </main>
            <footer className='bottom-0'>
                <Footer />
            </footer>
        </div>
    )
}

export default Layout