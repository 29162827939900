import React from 'react'
import rectangle from "../../assets/service/rectangle.png";
import can from "../../assets/service/can.svg";
import data from "../../assets/service/data.svg";
import tabulate from "../../assets/service/Tabulates.svg";

function Dataservices() {
  return (
    <div className="w-full text-center mb-16 sm:mt-24 mt-4">
      <div className="w-11/12 inline-block text-center" >
    <h1 className="font-bold text-4xl text-[#474747]">
      Data Services : Data visualization & Dashboard
    </h1>
    <p className="mt-4">
      Trayistats AI Technologies Data Visualization & Dashboard service
      goes beyond creating visually appealing representations of data. We
      understand that effective data visualization is not just about
      aesthetics, but also about providing clarity and understanding. Our
      team utilizes advanced data visualization techniques to transform
      complex datasets into intuitive visual displays. We carefully select
      the appropriate chart types, color schemes, and interactive features
      to ensure that the data is easily comprehensible and actionable. By
      presenting data in a visually appealing and user-friendly format, we
      enable our clients to quickly grasp key insights, identify trends,
      and make informed decisions. Our goal is to empower our clients with
      clear and impactful visualizations that unlock the true value of
      their data.
    </p>
  </div>
  <div className="w-full text-center mb-16 mt-16 bg-[#F5F5F5]">
          <div className="w-11/12 inline-block text-start">
            <div className="flex flex-col text-white w-full my-12">
              <div className="flex flex-col-reverse font-sans items-center w-full gap-6 mx-auto md:flex-row md:gap-0">
                <div className="w-full md:w-full">
                  <div className="">
                    <p className="lg:w-3/4 md:w-full w-full leading-[2rem] md:mt-4 text-[2rem] md:text-[3rem] text-[#484848] font-semibold md:text-left">
                      Features
                    </p>
                    <ul className="mt-8">
                      <li>
                        <img src={data} alt="" className="absolute" />
                        <p className="ml-10 mt-3 text-black">
                          Data can be prepared in Excel, SQL, SPSS, or Tableau
                          formats
                        </p>
                      </li>
                      <li>
                        <img src={tabulate} alt="" className="absolute" />
                        <p className="ml-10 mt-3 text-black">
                          Tabulates data and then presents it in the required
                          format
                        </p>
                      </li>
                      <li>
                        <img src={can} alt="" className="absolute" />
                        <p className="ml-10 mt-3 text-black">
                          Can produce offline dashboards that use advanced VBA
                          for data drill-downs
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <img src={rectangle} alt="" className="h-96 md:h-64" />
              </div>
            </div>
          </div>
        </div></div>
  )
}

export default Dataservices