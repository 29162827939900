
import React from 'react'
import { Link } from 'react-router-dom'
import bg from '../../assets/home/bg.png'
import Frame4 from '../../assets/home/Frame4.png'
import background from '../../assets/home/background.png'
import Frame2 from '../../assets/home/Frame2.png'


function Business() {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="text-center">
      <div className="relative">
          <img src={Frame2} alt="business" className="absolute w-full" />
          <div className="lg:w-7/12 md:w-7/12 sm:w-7/12 w-full text-center inline-block relative z-10">
            <h1 className="lg:text-5xl md:text-4xl sm:text-2xl text-lg text-[#886CC0] font-bold lg:mt-10 md:mt-10 sm:mt-10 mt-4">
              BUSINESS <span className="text-black">SOLUTIONS</span>
            </h1>
            <p className="lg:mt-5 md:mt-5 sm:mt-5 text-[#969696] text-sm lg:text-lg sm:text-lg sm:text-md p-2">
              We offer2 end-to-end business solutions, from market research
              design and data collection to analysis, interpretation, and
              actionable recommendations, ensuring our clients have the
              necessary insights to achieve their business goals.
            </p>
          </div>
          <img src={Frame4} alt="" className="w-full relative z-20" />
        </div>

        <div className="relative w-full justify-center">
          <div className="relative">
            <img
              src={background}
              alt=""
              className="w-full h-40 lg:h-96 md:h-96"
            />
          </div>
          <div className="flex justify-center items-center">
            <div className="absolute w-full lg:w-4/5 md:w-2/3 sm:w-2/3 justify-end h-full justify-center items-center lg:mb-10 md:mb-20 sm:mb-10 mb-16">
              <p className="text-center text-xs lg:text-3xl md:text-2xl sm:text-lg text-md font-semibold leading-7 md:w-full">
                <span className="text-white text-center">
                  Let's Connect and make your dream true together
                </span>
              </p>
              <div className="justify-center mt-2 lg:mt-5 md:mt-5 text-center transition ease-in-out">
                <button className="bg-[#886CC0] text-white hover:bg-[#fff] w-[80px] lg:w-[160px] md:w-[140px] rounded-md font-medium py-1 lg:py-2 md:py-2 sm:py-2  hover:text-[#886CC0] ">
                  <Link to='/contact' onClick={handleClick}>Contact Us</Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Business;
