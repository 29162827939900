import React from "react";
import { NavLink } from "react-router-dom";
import background from "../../assets/home/business-bg.png";
import card1 from "../../assets/home/card1.png";
import card2 from "../../assets/home/card2.png";
import card3 from "../../assets/home/card3.png";
import card4 from "../../assets/home/card4.png";
import card5 from "../../assets/home/card5.png";
import card6 from "../../assets/home/card6.png";
import card7 from "../../assets/home/card7.png";
import card8 from "../../assets/home/card8.png";
import card9 from "../../assets/home/card9.png";

function OurServices() {
  return (
    <>
      <div className="text-center bg-gray-200 pt-5 ">
        <img src={background} alt="business" className="absolute" />
        <div className="mt-10">
          <div className="w-7/12 text-center inline-block">
            <h1 className="text-4xl text-[#886CC0] font-extrabold">
              OUR <span className="text-black">SERVICES</span>
            </h1>
            <p className="mt-5 text-[#969696]">
              We offer end-to-end business solutions, from market research
              design and data collection to analysis, interpretation, and
              actionable recommendations, ensuring our clients have the
              necessary insights to achieve their business goals.
            </p>
          </div>
        </div>
        <div class="p-10 pb-6 sm:pb-6 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5 text-start mt-5">
          {/* <!--Card 1--> */}
          <div class="rounded-xl overflow-hidden shadow-lg bg-white z-10">
            <div class="px-6 py-4">
              <img class="w-20" src={card1} alt="Data Service" />
              <div class="font-bold text-xl mb-2 mt-4">
                Data Services : Data visualization & Dashboard
              </div>
              
              <p class="text-gray-700 text-base">
                Data Visualization & Dashboard service goes beyond creating
                visually appealing representations of data.
              </p>
            </div>
          </div>
          {/* <!--Card 2--> */}
          <div class="rounded-xl overflow-hidden shadow-lg bg-white z-10">
            <div class="px-6 py-4 mt-2">
              <img class="w-20" src={card2} alt="card2" />
              <div class="font-bold text-xl mb-2 mt-4">
                Computer-Assisted Web Interviewing (CAWI, Online)
              </div>
              <p class="text-gray-700 text-base">
                It leverages a network of dedicated panel partners that have the
                capability to serve 40 locations and regions
              </p>
            </div>
          </div>

          {/* <!--Card 3--> */}
          <div class="rounded-xl overflow-hidden shadow-lg bg-white z-10">
            <div class="px-6 py-4 mt-2">
              <img class="w-20" src={card3} alt="in-depth" />
              <div class="font-bold text-xl mb-2 mt-4">
                In-Depth Interviewing (IDI)
              </div>
              <p class="text-gray-700 text-base">
                We firmly believe that conducting interviews is a delicate art
                that requires a unique set of skills and qualities
              </p>
            </div>
          </div>
        </div>

        <div class="px-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5 text-start">
          {/* <!--Card 1--> */}
          <div class="rounded-xl overflow-hidden shadow-lg bg-white z-10">
            <div class="px-6 py-4">
              <img class="w-20" src={card4} alt="panel management" />
              <div class="font-bold text-xl mb-2 mt-4">Panel management</div>
              <p class="text-gray-700 text-base">
                Our specialty panel consists of a vast network of 400,000 IT
                Decision Makers and nearly 15 million unique consumers, spread
                across 40 countries.
              </p>
            </div>
          </div>
          {/* <!--Card 2--> */}
          <div class="rounded-xl overflow-hidden shadow-lg bg-white z-10">
            <div class="px-6 py-4 mt-2">
              <img class="w-20" src={card5} alt="secondary-resource" />
              <div class="font-bold text-xl mb-2 mt-4">Secondary Research</div>
              <p class="text-gray-700 text-base">
                While our primary research remains our primary focus, we
                understand the value of secondary research in providing
                comprehensive insights
              </p>
            </div>
          </div>

          {/* <!--Card 3--> */}
          <div class="rounded-xl overflow-hidden shadow-lg bg-white z-10">
            <div class="px-6 py-4 mt-2">
              <img class="w-20" src={card6} alt="Top line" />
              <div class="font-bold text-xl mb-2 mt-4">
                Top-line Reports/ Charts & Pivots
              </div>
              <p class="text-gray-700 text-base">
                As a value-added extra, we offer the production of a
                comprehensive Top-Line Report.
              </p>
            </div>
          </div>
        </div>

        <div class="p-10 pt-6 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5 text-start">
          {/* <!--Card 1--> */}
          <div class="rounded-xl overflow-hidden shadow-lg bg-white z-10">
            <div class="px-6 py-4">
              <img class="w-20" src={card7} alt="phone2web" />
              <div class="font-bold text-xl mb-2 mt-4">
                Phone2Web Via Screen Access
              </div>
              <p class="text-gray-700 text-base">
                While respondents navigate through the survey on their own, the
                interviewer remains on mute mode on the phone but is readily a
              </p>
            </div>
          </div>
          {/* <!--Card 2--> */}
          <div class="rounded-xl overflow-hidden shadow-lg bg-white z-10">
            <div class="px-6 py-4 mt-2">
              <img class="w-20" src={card8} alt="CATI" />
              <div class="font-bold text-xl mb-2 mt-4">
                Computer-Assisted Telephone Interviewing (CATI)
              </div>
              <p class="text-gray-700 text-base">
                CATI services, which have been refined and improved over many
                years of experience
              </p>
            </div>
          </div>

          {/* <!--Card 3--> */}
          <div class="rounded-xl overflow-hidden shadow-lg bg-white z-10">
            <div class="px-6 py-4 mt-2">
              <img class="w-20" src={card9} alt="survey programming" />
              <div class="font-bold text-xl mb-2 mt-4">
                Survey Programming & Hosting
              </div>
              <p class="text-gray-700 text-base">
                Survey Programming service is led by a highly capable and
                qualified team proficient in the latest market research survey
                tools across multiple platforms
              </p>
            </div>
          </div>
        </div>
        <div className="text-center h-24 mt-6">
          <NavLink
            smooth
            to=""
            activeClassName="text-red-500"
            className="bg-[#886CC0] hover:bg-[#886CC0] lg:px-36 md:px-36 sm:px-36 px-4  py-4  rounded-xl text-lg text-white hover:text-white shadow hover:shadow-2xl text-center"
          >
            Know more
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default OurServices;
