import React from 'react'
import Frame from '../../assets/home/Frame.svg';
import bgImage from '../../assets/home/bg_image.png';
import WhoWeAre from './WhoWeAre'
import Business from './Business'
import OurServices from './OurServices'
import OurTeam from './OurTeam'
import { NavLink } from 'react-router-dom'

function Home() {
  return (
    <>
    <div >
       <div className=" bg-cover bg-center w-full h-screen flex flex-col-reverse md:flex-row justify-center items-center mt-14 px-2 xl:px-40 " style={{ backgroundImage: `url(${bgImage})` }}>
      <div className="">
        <p className="pb-1 sm:pb-4">
          <span className="text-xl sm:text-2xl">Decode Customer Behavior  </span> <br /> <span className="text-[#886CC0] font-bold text-2xl sm:text-4xl ">Elevate Your Market Pulse</span>
        </p>
        <p className="text-xs xl:w-[70%] md:w-[70%] sm:w-[70%] w-[80%] ">
          Market research is the compass that guides your business towards profitable ventures. With data-backed insights, you can uncover hidden opportunities, understand consumer behavior, and make
          informed choices
        </p>
        <div className='text-center sm:text-left xl:h-full lg:h-full md:h-full sm:h-full h-[140px]  '>
          <button className="bg-[#886CC0]  text-white px-6 py-2 rounded-xl sm:mt-6 mt-4 hover:bg-white border-2 hover:text-[#886CC0] hover:border-[#886CC0]">Learn More</button>
        </div>
      </div>
      <div className="w-auto flex md:justify-end py-1 sm:py-4">
        <div className="w-[300px] sm:w-[400px] flex flex-col py-6 shadow-2xl rounded-xl bg-white">
          <div className="flex justify-center">
            <img src={Frame} alt="" />
          </div>
          <div className="w-full flex justify-center ">
            <p className="font-bold text-lg">
              <span className="px-10 sm:px-20 ">Unleash Your Influence.</span>
              <br /> <span className="px-4  sm:px-16"> Shape a Brighter Tomorrow.</span>
            </p>
          </div>
          <div className="w-full flex justify-center items-center">
            <div className="w-32 h-1 my-2 bg-[#886CC0]"></div>
          </div>
          <div className="w-full flex justify-center gap-16 my-2">
          </div>
        </div>
      </div>
    </div>
      <WhoWeAre />
      <Business />
      <OurServices />
      {/* <OurTeam /> */}
      </div>
    </>
  )
}

export default Home;