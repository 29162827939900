import React  from 'react'
import { Link } from 'react-router-dom'
import banner from '../../../assets/panel/banner.png'
import pic2 from '../../../assets/panel/pic2.png'
import industry from '../../../assets/panel/Industrial.png'
import business from '../../../assets/panel/business.png'
import service from '../../../assets/panel/services.png'
import background from '../../../assets/panel/background.png'

function B2BPanel() {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div>
        <div className="w-full flex items-center justify-center">
          <img className="h-auto w-full" src={banner} />
          <h1 className="absolute text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
            B2B Panel
          </h1>
        </div>
        <div className="w-full text-center bg-[#F5F5F6]">
          <div className="w-11/12 inline-block">
            <div className="flex justify-center mt-10">
              <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
                <div className="md:w-1/2 flex justify-center">
                  <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start p-4">
                    <p className="text-4xl font-bold text-black pb-4 mt-4">We Help you to reach the Decision makers ?</p>
                    <p className="text-black mb-10 text-gray-700">
                      Trayistats AI Technologies can be beneficial for accessing the C-Suite through their nonprofit relationships, which provide access to high-quality audiences. Their approach taps into diverse networks and may attract participation from C-Suite executives who support the causes represented by the nonprofits. However, it's important to assess Trayistats AI Technologies methodology, track record, screening processes, and the integrity of their respondent pool. Evaluate the cost-effectiveness and value of using Trayistats AI Technologies  compared to other approaches. Make the decision based on your research objectives, target audience, budget, and Trayistats AI Technologies credibility in delivering high-quality C-Suite participants.
                    </p>
                  </div>
                </div>
                <div className="md:w-1/2 flex justify-center">
                  <img src={pic2} alt="who we are" className="mt-5 lg:h-80 md:h-80 mb-5 " />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full text-center mb-16 mt-16">
          <div className="w-11/12 inline-block">
            <h1 className='text-5xl font-bold text-[#886CC0]'>Our Audiences</h1>
            <div class="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
              {/* <!--Card 1--> */}
              <div class="rounded-xl bg-[#F5F5F6]">
                <img class="w-full" src={industry} alt="Mountain" />
                <div class="px-6 py-4">
                  <div class="font-bold text-xl mb-2">Industrial</div>
                  <p class="text-gray-700 text-base">
                  General Contractors, Remodelers, HVAC, Architects, Painters, Welders, Plumbers, Farmers</p>
                </div>
              </div>
              {/* <!--Card 2--> */}
              <div class="rounded-xl bg-[#F5F5F6]">
                <img class="w-full" src={business} alt="River" />
                <div class="px-6 py-4">
                  <div class="font-bold text-xl mb-2">General Business</div>
                  <p class="text-gray-700 text-base">
                  C-Suite, Small Business Owners, Financial Decision Makers, Financial Advisors, VP Level and Above, IT Decision Makers, Human Resources Decision Makers, Marketers</p>
                </div>
              </div>

              {/* <!--Card 3--> */}
              <div class="rounded-xl bg-[#F5F5F6]">
                <img class="w-full" src={service} alt="Forest" />
                <div class="px-6 py-4">
                  <div class="font-bold text-xl mb-2">Services</div>
                  <p class="text-gray-700 text-base">
                  Teachers and Administrators, Bartenders, Lawyers, Hotel employees, Restaurant Owners, Chefs, C-Store Operators</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full justify-center">
          <div className="relative">
            <img src={background} alt="" className="w-full h-40 lg:h-96 md:h-96" />
          </div>
          <div className="flex justify-center items-center">
            <div className="absolute w-full lg:w-4/5 md:w-2/3 sm:w-2/3 justify-end h-full justify-center items-center lg:mb-10 md:mb-20 sm:mb-10 mb-16">
              <p className="text-center text-xs lg:text-3xl md:text-2xl sm:text-lg text-md font-semibold leading-7 md:w-full">
                <span className="text-white text-center">Let's Connect and make your dream true together</span>
              </p>
              <div className="justify-center mt-2 lg:mt-5 md:mt-5 text-center transition ease-in-out">
                <button className="bg-[#886CC0] text-white hover:bg-[#fff] w-[80px] lg:w-[160px] md:w-[140px] rounded-md font-medium py-1 lg:py-2 md:py-2 sm:py-2  hover:text-[#886CC0] ">
                  <Link to='/contact' onClick={handleClick}>Contact Us</Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default B2BPanel