import React from "react";
import privacy from "../../assets/privacypolicy/pp.png";

const Policy = () => {
  return (
    <div>
      {/* <img src={privacy} alt="" /> */}
      <div className="h-[310px] justify-center sm:mt-20 m=0">
        <img
          src={privacy}
          alt="absolute"
          className="min-w-full min-h-[300px]"
        />

        <h1 className=" text-white font-bold absolute top-32 xl:top-44 ml-12 sm:ml-30 text-[25px] xl:text-[30px] lg:ml-15 ">
          Welcome to our Business Company
        </h1>
        <p className="text-[#F4F4F4] text-[16px] absolute top-[230px] sm:top-[200px] md:top-[180px] xl:top-60 ml-12 sm:ml-30 lg:ml-15">
          Trust our experience to manage your business Real People.
        </p>
        <p className="text-[#F4F4F4] text-[16px] absolute top-[280px] sm:top-[200px] md:top-[200px] xl:top-[270px] ml-12 sm:ml-30 lg:ml-15">
          {" "}
          Real Time Results
        </p>
      </div>
      <div className=" xl:px-[151px] mx-[20px] pb-[120px] lg:mt-11 xl:mt-55">
        <div className="pt-10 pb-[30px] lg:mt-11 xl:mt-55">
          <p>Privacy Policy</p>
          <p>Effective Date: July 2023</p>
        </div>
        {/* INDRODUCTION */}
        <div className="flex flex-col gap-3 ">
          <h1 className="text-[#000000] text-[18px] font-[400] mt-5">
            Introduction
          </h1>
          <p className="text-[#000000A6] text-[14px] font-[400]">
            Thank you for choosing Trayistats AI Technologies as your
            trusted market research provider. This Privacy Policy outlines how
            we collect, use, disclose, and protect the personal information we
            gather from individuals who participate in our market research
            activities. We are committed to respecting your privacy and
            protecting your personal information in accordance with applicable
            data protection laws and regulations.
          </p>{" "}
          <p className="text-[#000000A6] text-[14px] font-[400]">
            At Trayistats AI Technologies, we highly value the privacy and
            security of your personal information. We are fully committed to
            ensuring the confidentiality, integrity, and protection of the data
            you share with us. This comprehensive document outlines our
            practices regarding the collection, use, disclosure, retention, and
            protection of personal information.
          </p>
        </div>
        <div className="mt-4 text-[#000000]">
          <p className="text-[14px] font-[400] text-[#000000A6]">
            <span className="font-bold  text-[16px] ">
              1.Information Collection and use :
            </span>
            We collect personal information solely for the purpose of conducting
            market research studies. This information may include your name,
            contact details, demographic data, and opinions expressed during
            surveys or interviews. Rest assured that we only gather the minimum
            necessary information relevant to our research objectives.
          </p>
          <div className="mt-4 flex flex-col gap-3">
            <h1 className="text-[14px] text-[400] ">
              {" "}
              1.1 Personal Information{" "}
            </h1>
            <ul className="text-[#000000A6]">
              When you participate in our market research activities, we may
              collect the following types of personal information:
              <ul className="list-disc ml-4">
                <li>Name</li>
                <li>
                  Contact information (such as email address, phone number, and
                  postal address)
                </li>
                <li>
                  Demographic information (such as age, gender, occupation, and
                  income level)
                </li>
                <li>
                  Responses and opinions provided during surveys, interviews, or
                  focus groups
                </li>
                <li>
                  Information about your preferences, interests, and behaviors
                  related to specific research topics
                </li>
                <li>
                  Any other information you voluntarily provide to us for the
                  purposes of the research study
                </li>
              </ul>
            </ul>
          </div>
        </div>

        <div className="mt-4 flex flex-col gap-3">
          <h1 className="text-[14px] text-[400]">
            1.2 Non Personal Information:
          </h1>
          <p className="text-[#000000A6]">
            In addition to personal information, we may collect non-personal
            information, such as aggregated data, statistical information, and
            technical information about your device and internet connection.
            This information does not directly identify you and is used to
            analyze trends, administer our website and services, and improve our
            market research offerings.
          </p>
          <p className="text-[#000000A6]">
            We want to assure you that we do not engage in the sale, trade, or
            rental of your personal information to third parties for marketing
            purposes. However, there are circumstances where we may share your
            information
          </p>
        </div>
        <div className="mt-3 text-[#000000]">
          <p className=" text-[#000000A6]">
            <span className="font-bold text-[16px] text-[400] ">
              3. Trusted Service Providers:
            </span>
            In order to effectively conduct market research and data collection
            activities, we may need to engage trusted service providers. These
            providers assist us in various aspects such as survey distribution,
            data processing, data storage, and analysis. We have contractual
            agreements with these service providers to ensure that they protect
            your information and use it only for specified purposes.
          </p>
        </div>
        <div className="mt-3 text-[#000000]">
          <p className="text-[#000000A6]">
            <span className="font-bold text-[16px] text-[400] ">
              4. Client Requirments:
            </span>
            Some of our market research studies are conducted on behalf of our
            clients who have specific research objectives. In certain cases, our
            clients may require your participation in these studies. We want to
            emphasize that your personal information is anonymized and
            aggregated to protect your identity. We only provide our clients
            with de-identified and non-attributable information.
          </p>
        </div>
        <div className="mt-3 text-[#000000]">
          <p className="text-[#000000A6]">
            <span className="font-bold text-[16px] text-[400] ">
              5.Legal Obligations:
            </span>
            We may disclose your personal information if required by law,
            regulation, or legal process. This includes instances where we are
            compelled to cooperate with government authorities, law enforcement
            agencies, or other third parties in response to a court order,
            subpoena, or other legal requirements.
          </p>
        </div>
        <div className="mt-3">
          <p className="text-[#000000A6]">
            <span className="font-bold text-[16px] text-[400] ">
              {" "}
              6. Corporate Events:
            </span>
            In the event of a corporate merger, acquisition, or sale, your
            personal information may be transferred to the acquiring entity or
            successor while maintaining the same level of data protection and
            security.
          </p>
        </div>
        <div className="mt-3">
          <h1 className="text-[18px] font-[400] ">
            2. How We Use Your Information{" "}
          </h1>
          <ul className="text-[14px] font-[400] text-[#000000A6]">
            We use the personal information we collect for the following
            purposes:
            <ul className="list-disc ml-4">
              <li>
                Conducting market research studies and surveys, including data
                analysis and interpretation
              </li>
              <li>
                Communicating with you about research opportunities, updates,
                and results
              </li>
              <li>
                Personalizing and improving our services to enhance your
                research experience
              </li>
              <li>
                Generating aggregated and anonymized reports and insights for
                our clients Complying with legal obligations and protecting our
                rights and interests
              </li>
            </ul>
          </ul>
        </div>
        <div className="flex flex-col gap-3 mt-3">
          <h1 className="text-[#000000]">3. Data Security and Retention</h1>
          <p className="text-[#000000A6]">
            We have implemented stringent measures to protect your personal
            information from unauthorized access, disclosure, alteration, or
            destruction. These measures encompass physical, technical, and
            administrative safeguards that are regularly reviewed and updated to
            mitigate any potential risks. Our employees are trained on data
            privacy and security practices, and access to personal information
            is limited to authorized individuals who require it for legitimate
            business purposes. We retain your personal information only for as
            long as necessary to fulfill the purposes for which it was collected
            and to comply with legal obligations. Once your personal information
            is no longer required, we securely dispose of it in accordance with
            applicable laws and regulations.
          </p>
        </div>
        <div>
          <h1 className="text-[#000000]">4. Disclosure of Your Information</h1>
          <ul className="text-[#000000A6]">
            We may disclose your personal information in the following
            circumstances:
            <ul className="list-disc ml-4">
              <li>
                To our trusted third-party service providers and research
                partners who assist us in conducting market research studies and
                surveys. These entities are contractually obligated to handle
                your personal information in a manner consistent with this
                Privacy Policy.
              </li>
              <li>
                In response to a legal request, court order, or when required by
                law enforcement agencies or applicable regulations.
              </li>
              <li>
                In the event of a business transfer or acquisition, your
                personal information may be transferred to the new entity.
              </li>
              <li>
                {" "}
                We do not sell, rent, or lease your personal information to
                third parties for their marketing purposes.
              </li>
            </ul>
          </ul>
        </div>
        <div className="flex flex-col gap-3 mt-3">
          <h1 className="text-[#000000]">5. Your Rights and Choices</h1>
          <ul className="text-[#000000A6]">
            {" "}
            You have certain rights regarding your personal information. Subject
            to applicable laws, you may exercise the following rights:
            <ul className="ml-4 list-disc">
              <li>
                Access: You can request access to the personal information we
                hold about you and receive a copy of that information.
              </li>
              <li>
                Rectification: You can request the correction or update of any
                inaccurate or incomplete personal information we have about you.
              </li>
              <li>
                Erasure: You can request the deletion of your personal
                information in certain circumstances, such as when it is no
                longer necessary for the purposes for which it was collected.
              </li>
              <li>
                Objection: You can object to the processing of your personal
                information based on legitimate interests or for direct
                marketing purposes
              </li>
              <li>
                Restriction: You can request the restriction of processing of
                your personal information in certain circumstances, such as when
                its accuracy is contested. Data Portability: You can request to
                receive a structured, commonly used, and machine-readable format
                of your personal information or have it transferred to another
                controller, where technically feasible.
              </li>
            </ul>
          </ul>
        </div>
        <div className="flex flex-col gap-3 mt-3">
          <h1 className="text-[#000000]">6. Updates to the Privacy Policy</h1>
          <p className="text-[#000000A6] text-[14px]">
            To reflect changes in our practices or legal requirements, we may
            update this privacy policy periodically. We will notify you of any
            material changes by posting the revised <br />
            policy on our website or through other appropriate communication
            channels
          </p>
        </div>
        <div className="flex flex-col mt-3 gap-3">
          <h1 className="text-[#000000] font-[400]">Contact Us</h1>
          <p className="text-[#000000A6] text-[14px] ">
            If you have any questions, concerns, or requests regarding this
            privacy policy or the handling of your personal information, please
            reach out to us at: Trayistats AI Technologies, Province Country
            privacy@trayistats.com We are dedicated to promptly addressing and
            resolving your inquiries or concerns in a timely manner. Thank you
            for trusting Trayistats AI Technologies  with your personal
            information. We hold your privacy in high regard and strive to
            maintain the highest standards of data protection
          </p>
        </div>
      </div>
    </div>
  );
};

export default Policy;
