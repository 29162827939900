import React, { useState, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import tslogo from "../../assets/service/tslogo.svg";
import { NavSmallScreen } from "./NavSmallScreen";
import { useLocation } from "react-router-dom";
import ServiceContainer from "./ServiceContainer";
import { useOutsideClick } from "./useOutsideClick";

// type ToggleMobileMenu = () => void;

function Header() {
  const ServiceMenuRef = useRef(null);
  const panelMenuReff = useRef(null);
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [IsServices, setIsServices] = useState(false);
  const { pathname } = location;
  const LocationHome = pathname.match(/\/$/);
  const LocationService = pathname.includes("/services");
  const LocationAboutUs = pathname.includes("/about-us");
  const LocationPolicy = pathname.includes("/policy");
  const LocationB2BPanel = pathname.includes("/b2b-panel");
  const LocationBCBPanel = pathname.includes("/bcb-panel");
  const LocationHealthCare = pathname.includes("/healthcare");
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLinkClick = () => {
    setIsDropdownOpen(false);
  };

  const mobileMenuToggle = () => {
    setIsNavOpen((prev) => !prev);
  };

  const showServices = () => {
    setIsServices(!IsServices);
  };
  useOutsideClick(ServiceMenuRef, () => {
    setIsServices(false);
  });
  useOutsideClick(panelMenuReff, () => {
    setIsDropdownOpen(false);
  });

  return (
    <>
      <div className="relative shadow-xl sm:fixed top-0 z-30 w-full  mx-auto bg-white max-w-10xl sm:px-6 md:px-8 md:w-full h-[70px]">
        <div className="flex items-center justify-between md:justify-start md:w-full h-[70px]">
          <div className="flex lg:px-5 lg:flex-1 w-full    ">
            <Link to={"/"}>
              <img
                className="w-20 lg:w-full md:w-full sm:w-full my-4 h-[50px]"
                src={tslogo}
                alt="mainLogo"
              />
            </Link>
            <div className="items-center hidden md:flex md:flex-1 md:w-full lg:w-0 justify-end  ">
              <div className="flex items-center h-full">
                <div className={LocationHome ? "active" : ""}>
                  <NavLink to="/" activeClassName="active" className="">
                    Home
                  </NavLink>
                </div>
                <div
                  // className={`relative ${LocationService ? "active" : ""}`}
                  // onClick={showServices}
                >
                  <NavLink
                    exact
                    to="/services"
                    activeClassName="text-red-500"
                    className="ml-8"
                    ref={ServiceMenuRef}
                  >
                    Services
                  </NavLink>
                </div>

                {IsServices ? (
                  <ServiceContainer />
                ) : (
                  ""
                )}
                <div className={LocationAboutUs ? "active" : ""}>
                  <NavLink
                    exact
                    to="/about-us"
                    activeClassName="active"
                    className="ml-8"
                  >
                    About Us
                  </NavLink>
                </div>
                <div>
                  <div className="relative ml-8" ref={panelMenuReff} >
                    <button
                      onClick={handleDropdownToggle}
                      className="flex items-center focus:outline-none"
                    >
                      Panel
                      <svg
                        className={`w-4 h-4 ml-2 transition-transform duration-200 transform ${
                          isOpen ? "rotate-180" : ""
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M10 14l6-6H4l6 6z" />
                      </svg>
                    </button>
                    {isDropdownOpen && (
                      <div className="absolute left-0 w-40 top-5 mt-2 ml-1 text-gray-700 bg-white rounded-md shadow-lg "     >
                     
                        <div
                          className={LocationB2BPanel ? "active" : "inactive"}
                        >
                          <NavLink
                            smooth
                            to="/b2b-panel"
                            activeClassName="text-red-500"
                            className="block px-4 py-2 hover:bg-gray-100"
                            onClick={handleLinkClick}
                          >
                            B2B Panel
                          </NavLink>
                        </div>
                        <div className={LocationBCBPanel ? "active" : ""}>
                          <NavLink
                            smooth
                            to="/b2c-panel"
                            activeClassName="text-red-500"
                            className="block px-4 py-2 hover:bg-gray-100"
                            onClick={handleLinkClick}
                          >
                            B2C Panel
                          </NavLink>
                        </div>
                        <div className={LocationHealthCare ? "active" : ""}>
                          <NavLink
                            smooth
                            to="/healthcare"
                            activeClassName="text-red-500"
                            className="block px-4 py-2 hover:bg-gray-100"
                            onClick={handleLinkClick}
                          >
                            Healthcare Panel
                          </NavLink>
                        </div>
                        </div>
                      
                    )}
                  </div>
                </div>
                <div className={LocationPolicy ? "active" : ""}>
                  <NavLink
                    smooth
                    to="/policy"
                    activeClassName="text-red-500"
                    className="ml-8 "
                  >
                    Privacy Policy
                  </NavLink>
                </div>

                <NavLink
                  smooth
                  to="/contact"
                  activeClassName="text-red-500"
                  className="bg-[#886CC0] px-4 py-2 rounded-lg text-white ml-8 flex items-center hover:bg-[#886CC0] hover:text-white"
                >
                  Contact Us
                </NavLink>
              </div>
            </div>
          </div>
          <section className="flex MOBILE-MENU md:hidden lg:hidden">
            <button
              className="px-5 space-y-2 HAMBURGER-ICON "
              onClick={mobileMenuToggle}
            >
              <span className=""></span>
              <span className=""></span>
              <span className=""></span>
            </button>
            {isNavOpen ? <NavSmallScreen toggle={mobileMenuToggle} /> : null}
          </section>
        </div>
      </div>
    </>
  );
}

export default Header;
