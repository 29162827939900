import React from 'react'
import expertise from "../../assets/service/expertise.svg";
import custom from "../../assets/service/customize.svg";
import cato from "../../assets/service/CATO.png";
import speciality from "../../assets/service/specialty.svg";
import device from "../../assets/service/device.svg";
import quick from "../../assets/service/quick.svg";


function Computertelephonic() {
  return (
    <div><div className="w-full text-center mb-16 mt-16">
    <div
      className="w-11/12 inline-block text-center"
      id="computer-assisted"
    >
      <h1 className="font-bold text-4xl text-[#474747]">
        Computer-Assisted Telephone Interviewing (CATI)
      </h1>
      <p className="mt-4">
        Trayistats AI Technologies LLC specializes in Computer-Assisted
        Telephone Interviewing (CATI) services, which have been refined
        and improved over many years of experience. Our proven method
        consistently generates excellent data, ensuring reliable and
        valuable insights for our clients.
      </p>
      <p className="mt-4">
        What truly distinguishes our CATI services is the fact that our
        trio of founders started their careers as CATI interviewers. This
        firsthand experience provides us with a strong understanding of
        the intricacies of conducting telephone interviews. It also
        enables us to build a robust team under their guidance, with a
        focus on knowledge transfer and continuous improvement.
      </p>
      <p className="mt-4">
        We recognize that effective CATI requires a collaborative and
        process-oriented approach. Our Human Resources, Project
        Management, Software, and IT, Operations, Quality & Delivery teams
        work seamlessly together to ensure the highest standards of
        quality and accuracy in every project. By fostering a culture of
        teamwork and emphasizing streamlined processes, we maximize the
        efficiency and effectiveness of our CATI services.
      </p>
      <p className="mt-4">
        At Trayistats AI Technologies LLC, we are committed to delivering
        outstanding CATI results through our experienced team,
        comprehensive understanding of the subject matter, and a
        collaborative approach that prioritizes teamwork and process
        orientation. Our dedication to excellence ensures that our clients
        receive exceptional quality and reliable data for their research
        needs.
      </p>
    </div>
  </div>
  <div className="w-full text-center mb-16 mt-16 bg-[#F5F5F5]">
    <div className="w-11/12 inline-block text-start">
      <div className="flex flex-col text-white w-full my-12">
        <div className="flex flex-col-reverse font-sans mx-auto items-center w-full gap-6 mx-auto md:flex-row md:gap-0">
          <div className="w-full md:w-full">
            <div className="">
              <p className="lg:w-3/4 md:w-full w-full leading-[2rem] md:mt-4 text-[2rem] md:text-[3rem] text-[#484848] font-semibold md:text-left">
                Features
              </p>
              <ul className="mt-8">
                <li>
                  <img src={quick} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    24 x 7 Project Management Support
                  </p>
                </li>
                <li>
                  <img src={device} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Highly Skilled and Experienced Interviewing Team
                  </p>
                </li>
                <li>
                  <img src={speciality} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Dedicated Quality and Delivery Team
                  </p>
                </li>
                <li>
                  <img src={custom} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Innovative Incentive Mechanisms
                  </p>
                </li>
                <li>
                  <img src={expertise} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Dedicated Sample List Procurement and Management Team
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <img src={cato} alt="" className="h-full md:h-64" />
        </div>
      </div>
    </div>
  </div></div>
  )
}

export default Computertelephonic