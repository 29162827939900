import React from 'react'
import phone from "../../assets/service/phone-web.png";
import profiling from "../../assets/service/profiling.svg";
import rapid from "../../assets/service/rapid.svg";
import secondary from "../../assets/service/secondary.png";
import support from "../../assets/service/responsive.svg";
import custom from "../../assets/service/customize.svg";
import expertise from "../../assets/service/expertise.svg";
import device from "../../assets/service/device.svg";
import speciality from "../../assets/service/specialty.svg";
import quick from "../../assets/service/quick.svg";
import customizes from "../../assets/service/customizes.svg";
import tabulate from "../../assets/service/Tabulates.svg";





function Secondaryresearch() {
  return (
    <div> <div className="w-full text-center mb-16 sm:mt-24 mt-4">
    <div
      className="w-11/12 inline-block text-center"
      id="secondary-research"
    >
      <h1 className="font-bold text-4xl text-[#474747]">
        Secondary Research
      </h1>
      <p className="mt-4">
        At Trayistats AI Technologies LLC , our commitment to data
        gathering extends beyond limits. We go above and beyond to cater
        to our client’s diverse data requirements. While our primary
        research remains our primary focus, we understand the value of
        secondary research in providing comprehensive insights. That's why
        we have a dedicated team of highly skilled web researchers who
        specialize in profiling the companies targeted for our primary
        research initiatives. Their expertise ensures accurate and
        detailed profiles, contributing to the success of our projects.
      </p>
      <p className="mt-4">
        In situations where secondary research is required, our proficient
        web researchers are readily available to employ their expertise in
        conducting thorough investigations. They have a deep understanding
        of various online platforms and resources, allowing them to
        swiftly extract relevant information from reputable portals like
        D&B/Aventine. These subscriptions provide us with access to a vast
        array of secondary research data, enabling us to gather accurate
        and up-to-date insights in a timely manner.
      </p>
      <p className="mt-4">
        Trayistats AI Technologies LLC 's Secondary Research service is
        solely dedicated to meeting our client’s data needs with utmost
        precision and reliability. We understand the significance of
        accurate data in driving informed decision-making, and our
        commitment to excellence ensures that we deliver nothing less.
        Whether it's primary or secondary research, we uphold the highest
        standards of quality to provide our clients with actionable
        insights and a competitive edge in their respective industries.
      </p>
      <p className="mt-4">
        Our integrated approach, combining primary and secondary research
        methodologies, sets us apart as a comprehensive and trusted
        research partner. With Trayistats AI Technologies LLC , clients
        can rely on us to gather accurate and timely data, empowering them
        to make informed strategic decisions and stay ahead in today's
        dynamic business landscape.
      </p>
    </div>
  </div>
  <div className="w-full text-center mb-16 mt-16 bg-[#F5F5F5]">
    <div className="w-11/12 inline-block text-start">
      <div className="flex flex-col text-white w-full my-12">
        <div className="flex flex-col-reverse font-sans mx-auto items-center w-full gap-6 mx-auto md:flex-row md:gap-0">
          <div className="w-full md:w-full">
            <div className="">
              <p className="lg:w-3/4 md:w-full w-full leading-[2rem] md:mt-4 text-[2rem] md:text-[3rem] text-[#484848] font-semibold md:text-left">
                Features
              </p>
              <ul className="mt-8">
                <li>
                  <img src={customizes} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Customized research approach
                  </p>
                </li>
                <li>
                  <img src={profiling} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Profiling companies for primary research
                  </p>
                </li>
                <li>
                  <img src={tabulate} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Subscriptions to data portals
                  </p>
                </li>
                <li>
                  <img src={rapid} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Rapid acquisition of secondary research data
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <img src={secondary} alt="" className="h-96 md:h-64" />
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}

export default Secondaryresearch