import React from "react";
import { Link } from "react-router-dom";
import tslogo from "../../assets/home/tslogo.png";
import mobile from "../../assets/footer/mobile.svg";
import mail from "../../assets/footer/mail.svg";
import location from "../../assets/footer/location.svg";
// import facebook from "../../assets/footer/facebook.svg"
// import insta from "../../assets/footer/insta.svg"
import linked from "../../assets/footer/linked.svg";

function Footer() {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
const CopyrightYear = new Date().getFullYear()
  return (
    <>
      <div className="relative bg-[#F5F5F5] bg-neutral-100 dark:text-neutral-200 w-full text-center  ">
        <div className="w-11/12 inline-block text-center py-4  md:text-left pt-10 ">
          <div className="w-full grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="">
              <div className="flex items-center  mt-6 md:mt-0 md:w-full">
                <div className="relative w-10/12 h-full max-w-md lg:w-40 justify-start">
                  <img src={tslogo} alt="logo" className="w-20 mb-4" />
                </div>
              </div>
              <div className="text-start mt-2 w-full">
                <p className="text-black font-semibold text-xl">Subscribe</p>
                <p className="text-[#8E8E8E] mt-2 font-medium">
                  Stay in the loop with our exclusive newletter. Join now for
                  the latest updates and special offers!
                </p>
              </div>
              <div className="relative mt-4 w-full lg:w-full">
                <input
                  type="email"
                  className="block h-11 w-full p-4 text-sm text-gray-900 border border-gray-500 rounded-full placeholder-gray-500 text-black focus:outline-none focus:border-gray-500"
                  placeholder="Email Address"
                />
                <button
                  type="submit"
                  className="text-white absolute right-1 bottom-1 h-9 bg-[#886CC0] font-medium rounded-full text-md px-6"
                >
                  Subscribe
                </button>
              </div>
              <p className="text-[#8E8E8E] mt-3 mb-5">
                By subscribing you agree to the Terms of Services.
              </p>
            </div>
            <div className="">
              <h6 className="flex justify-center mb-4 font-semibold uppercase md:justify-start text-[#000000]">
                About Us
              </h6>
              <ul>
                <li className="my-1">
                  <Link
                    to="/"
                    className="text-[100%] text-[#8E8E8E] hover:underline"
                  >
                    Home
                  </Link>
                </li>
                <li className="my-2">
                  <Link
                    to="/b2b-panel"
                    className="text-[100%] text-[#8E8E8E] hover:underline"
                    onClick={handleClick}
                  >
                    B2B panel
                  </Link>
                </li>
                <li className="my-2">
                  <Link
                    to="/b2c-panel"
                    className="text-[100%] text-[#8E8E8E] hover:underline"
                    onClick={handleClick}
                  >
                    B2C panel
                  </Link>
                </li>
                <li className="my-2">
                  <Link
                    to="/healthcare"
                    className="text-[100%] text-[#8E8E8E] hover:underline"
                    onClick={handleClick}
                  >
                    Healthcare panel
                  </Link>
                </li>
                <li className="my-2">
                  <Link
                    to="/services"
                    className="text-[100%] text-[#8E8E8E] hover:underline"
                    onClick={handleClick}
                  >
                    Services
                  </Link>
                </li>
                <li className="my-2">
                  <Link
                    to="/contact"
                    className="text-[100%] text-[#8E8E8E] hover:underline"
                    onClick={handleClick}
                  >
                    Contact Us
                  </Link>
                </li>
                <li className="my-2">
                  <Link
                    to="/policy"
                    className="text-[100%] text-[#8E8E8E] hover:underline"
                    onClick={handleClick}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li className="my-2">
                  <Link
                    to="/terms"
                    className="text-[100%] text-[#8E8E8E] hover:underline"
                    onClick={handleClick}
                  >
                    Term & Conditions
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h6 className="flex justify-center mb-4 font-semibold uppercase md:justify-start text-[#000000]">
                Contact Information
              </h6>
              {/* <p className="flex items-center justify-center mb-4 md:justify-start  text-[#8E8E8E]">
                <img src={mobile} alt="mobile" className="mr-5" />
                +91-9802474839
              </p> */}
              <p className="flex items-center  mb-4 md:justify-start  text-[#8E8E8E]">
                <img src={mail} alt="mobile" className="mr-5" />
                <Link to="mailto:support@trayistats.com" target="blank">
                  support@trayistats.com
                </Link>
              </p>
              <p className='flex items-center mb-4 md:justify-start  text-[#8E8E8E]'>
                <img src={location} alt='address' className='mr-5 mt-[-50px]' />
                <div className='flex-col'>
                  <h4 className='sm:text-left text-left'> Registered Office</h4>
                  <h4 className='text-left sm:text-left'>
                    C/07/4,Centurian Park-Low Rise, Plot GH-05,
                    <h4 className='sm:text-left text-left' >
                      Techzone-4, Greater Noida West, Gautam Budh Nagar 201306
                    </h4>
                  </h4>
                </div>
              </p>
              <p className='flex items-center mb-4 md:justify-start  text-[#8E8E8E]'>
                <img src={location} alt='address' className='mr-5' />
                <div className='flex-col'>
                  <h4 className='sm:text-left text-left'> Corporate Office</h4>
                  <h3 className='sm:text-left text-left'> C-317, Cyber Park, Sector-62, Noida-201301</h3>
                </div>
              </p>
            </div>
          </div>
        </div>
        <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
        <div className=" p-4 sm:p-8 bg-white-500 bg-neutral-100 text-black grid grid-1 md:grid-cols-3">
          <div className="flex  justify-center xl:justify-start  ">
            <svg
              className="mt-1 mx-2"
              viewBox="0 0 50 50"
              width="16"
              height="16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25 0C11.214 0 0 11.216 0 25C0 38.784 11.215 50 25 50C38.786 50 50 38.784 50 25C50 11.216 38.785 0 25 0ZM25 45.001C13.971 45.001 4.999 36.029 4.999 25C4.999 13.973 13.97 5.001 25 5.001C36.028 5.001 44.999 13.973 44.999 25C44.998 36.029 36.027 45.001 25 45.001Z"
                fill="#0A142F"
              />
              <path
                d="M18.413 17.337C19.3392 16.3552 20.4461 15.5614 21.673 14.999C22.9254 14.4236 24.2888 14.1301 25.667 14.139C27.416 14.139 29.021 14.556 30.484 15.39C31.9262 16.2056 33.155 17.3508 34.07 18.732L37.634 16.067C36.28 14.2184 34.5207 12.7044 32.491 11.641C30.429 10.547 28.153 10 25.666 10C23.617 10 21.691 10.396 19.889 11.19C18.1276 11.9517 16.5279 13.043 15.176 14.405C13.8261 15.7752 12.7483 17.389 12 19.161C11.221 20.975 10.832 22.924 10.832 25.001C10.832 27.076 11.221 29.023 12 30.842C12.7475 32.6132 13.8254 34.2259 15.176 35.594C16.528 36.9569 18.1276 38.0495 19.889 38.813C21.692 39.603 23.618 40 25.666 40C28.153 40 30.428 39.453 32.491 38.358C34.5219 37.2962 36.2815 35.7819 37.634 33.932L34.07 31.271C33.1535 32.6501 31.925 33.794 30.484 34.61C29.021 35.443 27.416 35.86 25.667 35.86C24.2888 35.8694 22.9253 35.5758 21.673 35C20.4459 34.438 19.3389 33.6441 18.413 32.662C17.485 31.682 16.747 30.531 16.2 29.221C15.653 27.911 15.38 26.502 15.38 25.001C15.38 23.497 15.653 22.09 16.2 20.779C16.747 19.468 17.485 18.321 18.413 17.337Z"
                fill="#0A142F"
              />
            </svg>
            <p>copyright by trayistats {CopyrightYear}</p>
          </div>

          <p className="flex-auto">
            <Link className="p-2 sm:p-10" to="/terms" onClick={handleClick}>
            <span className=""> Terms & Condition</span>
             
            </Link>
           
            <Link onClick={handleClick} to="/policy">
            <span> Privacy Policy</span>
             
            </Link>
          </p>
          <div className="flex justify-center ml-auto">
            <p className="xl:mr-5 lg:mr-5 md:mr-5 sm:mr-5 mr-[130px] text-center text-">Follow us on:</p>
            {/* <a
              href="https://www.facebook.com/people/Pando-India-Software-Consultants/100089263113195/"
              className="mr-6 ml-auto mt-1 text-neutral-600"
              target="_blank"
              rel="noreferrer"
            >
               <div className="w-6 h-6">
               <img src={facebook} alt="facebook" />
               </div>
              
            </a> */}
            {/* <a

              href="https://www.instagram.com/zampliasurveys/"
              className="mr-6 mt-1 text-neutral-600"
              target="_blank"
              rel="noreferrer"
            >
             <div className="w-6 h-6"> <img src={insta} alt="insta" /></div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
            </a> */}

            <a 
              href="https://www.linkedin.com/company/trayistats-ai/mycompany/"
              target="_blank"
              rel="noreferrer"
              className="absolute xl:-mr-[110px] lg:-mr-[110px] md:-mr-[110px] sm:-mr-[110px] -mr-[0px] mt-0 text-neutral-600"
            >
              <div className="w-6 h-6">
                <img src={linked} alt="linked" />
              </div>
            </a>
            {/* <a
              href="https://twitter.com"
              target="_blank"
              rel="noreferrer"
              className="mr-6 mt-1 text-neutral-600"
            >
                
              
            </a> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
