import React from "react";
import panel from "../../assets/service/panel.png";
import quick from "../../assets/service/quick.svg";
import custom from "../../assets/service/customize.svg";
import expert from "../../assets/service/expert.svg";
import speciality from "../../assets/service/specialty.svg";

import expertise from "../../assets/service/expertise.svg";

function Panelmanagement() {
  return (
    <div>
      <div className="w-full text-center mb-16 sm:mt-24 mt-4">
        <div className="w-11/12 inline-block text-center" id="panel-management">
          <h1 className="font-bold text-4xl text-[#474747]">
            Panel Management
          </h1>
          <p className="mt-4">
            Trayistats AI Technologies takes pride in offering clients access to
            our exceptional Panel Management service. Our specialty panel
            consists of a vast network of 400,000 IT Decision Makers and nearly
            15 million unique consumers, spread across 40 countries.
          </p>
          <p className="mt-4">
            The selection and recruitment of Trayistats AI Technologies Panel
            Management team are carried out with meticulous care. We conduct
            thorough screenings and enlist the assistance of D&B (Dun &
            Bradstreet) to acquire a representative sample via telephone. This
            approach ensures that our panel members are not sourced from mailing
            lists or unrepresentative sources. To meet the specific requirements
            of web-based studies, we also recruit additional panelists by phone,
            optimizing both cost and time efficiency. Our dedicated team of
            panelists is regularly maintained and refreshed to ensure the
            highest quality of data.
          </p>
          <p className="mt-4">
            With Trayistats AI Technologies LLC's Panel Management service, we
            prioritize recruiting the right individuals who match the specific
            criteria for accurate data collection. By ensuring that our panel
            consists of relevant and engaged participants, we enhance the
            reliability and validity of the insights generated through our
            research studies. Our commitment to maintaining a robust and
            representative panel underscores our dedication to delivering
            accurate and meaningful data to our clients.
          </p>
          <p className="mt-4">
            Through our Panel Management service, Trayistats AI Technologies LLC
            empowers clients with access to a vast network of qualified
            participants, ensuring that every research endeavor is met with
            accuracy and efficacy.
          </p>
        </div>
      </div>
      <div className="w-full text-center mb-16 mt-16 bg-[#F5F5F5]">
        <div className="w-11/12 inline-block text-start">
          <div className="flex flex-col text-white w-full my-12">
            <div className="flex flex-col-reverse font-sans  items-center w-full gap-6 mx-auto md:flex-row md:gap-0">
              <div className="w-full md:w-full">
                <div className="">
                  <p className="lg:w-3/4 md:w-full w-full leading-[2rem] md:mt-4 text-[2rem] md:text-[3rem] text-[#484848] font-semibold md:text-left">
                    Features
                  </p>
                  <ul className="mt-8">
                    <li>
                      <img src={speciality} alt="" className="absolute" />
                      <p className="ml-10 mt-3 text-black">Specialty Panel</p>
                    </li>
                    <li>
                      <img src={expert} alt="" className="absolute" />
                      <p className="ml-10 mt-3 text-black">
                        Phone Recruitment for Web Studies
                      </p>
                    </li>
                    <li>
                      <img src={expertise} alt="" className="absolute" />
                      <p className="ml-10 mt-3 text-black">
                        Expertise in IT Decision Makers
                      </p>
                    </li>
                    <li>
                      <img src={custom} alt="" className="absolute" />
                      <p className="ml-10 mt-3 text-black">
                        Access to a Large Consumer Base
                      </p>
                    </li>
                    <li>
                      <img src={quick} alt="" className="absolute" />
                      <p className="ml-10 mt-3 text-black">
                        Cost-Effective and Time-Saving Recruitment
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <img src={panel} alt="" className="h-96 md:h-64" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Panelmanagement;
