import React, { useState, useCallback, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useOutsideClick } from "./useOutsideClick";
import ServiceContainer from "./ServiceContainer";
import { NavLink , useLocation } from "react-router-dom";

export const NavSmallScreen = ({ toggle }) => {
  const location = useLocation();
  const { pathname } = location;
  const ServiceMenuRef = useRef(null);
  const [IsServices, setIsServices] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const panelMenuReff = useRef(null);
  const LocationB2BPanel = pathname.includes("/b2b-panel");
  const LocationBCBPanel = pathname.includes("/bcb-panel");
  const LocationHealthCare = pathname.includes("/healthcare");

  const showServices = () => {
    setIsServices(!IsServices);
  };


  useOutsideClick(ServiceMenuRef, () => {
    setIsServices(false);
  });

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  
  const handleLinkClick = () => {
    setIsDropdownOpen(false);
  };
  

  
  return (

    <div
      className={
        "block absolute w-full top-0 left-0 z-50 flex-col justify-evenly items-center bg-white overflow-x-auto"
      }
    >
      <button
        className=" w-full absolute top-0  mt-8 ml-3"  
        onClick={(mobileMenuToggle) => {
          toggle(mobileMenuToggle);
        }}
      >
        <svg
          className="w-8 h-8 text-gray-600"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </button>
      <ul className="  w-full nav-links flex flex-col mt-20 text-center justify-center  min-h-[400px]">
        <li className="nav-text">
          <Link
            to="/"
            className="links"
            onClick={() => {
              toggle();
            }}
          >
            <span className="font-semibold">Home</span>
          </Link>
        </li>

        <li className="nav-text">
          <Link
            to="/services"
            className="links"
            ref={ServiceMenuRef}
            onClick={() => {
              toggle();
            }}

          >
            <span onClick={showServices}  className="font-semibold">
              Services
            </span>
          </Link>
        </li>
        {IsServices ? <ServiceContainer /> : null}

        <li className=" nav-text">
          <Link
            to="/about-us"
            className="links"
            onClick={() => {
              toggle();
            }}
          >
            <span className="font-semibold">About Us</span>
          </Link>
        </li>
        <li className=" nav-text relative ">
            <Link
              className="links"
                onClick={handleDropdownToggle}
            >
               <div className=" flex justify-center font-semibold ">
               Panel
              <svg
             
                        className={`w-6  h-6   transition-transform duration-200 transform ${
                          isOpen ? "rotate-180" : ""
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M10 14l6-6H4l6 6z" />
                    
                      </svg>
                          
              </div>
            </Link>
            {isDropdownOpen && (
                      <div className="absolute left-0 w-40 top-5 mt-2 ml-1 text-gray-700 bg-white rounded-md shadow-lg "     >
                        <div
                          className={LocationB2BPanel ? "active" : "inactive"}
                        >
                          <a
                            smooth
                            href="/b2b-panel"
                            activeClassName="text-red-500"
                            className="block px-4 py-2 hover:bg-gray-100"
                            onClick={handleLinkClick}
                          >
                            B2B Panel
                          </a>
                        </div>
                        <div className={LocationBCBPanel ? "active" : ""}>
                          <a
                            smooth
                            href="/b2c-panel"
                            activeClassName="text-red-500"
                            className="block px-4 py-2 hover:bg-gray-100"
                            onClick={handleLinkClick}
                          >
                            B2C Panel
                          </a>
                        </div>
                        <div className={LocationHealthCare ? "active" : ""}>
                          <a
                            smooth
                            href="/healthcare"
                            activeClassName="text-red-500"
                            className="block px-4 py-2 hover:bg-gray-100"
                            onClick={handleLinkClick}
                          >
                            Healthcare Panel
                          </a>
                        </div>
                        </div>
                    )}
        </li>
        <li className=" nav-text">
          <Link
            to={"/contact"}
            className="links"
            onClick={() => {
              toggle();
            }}
          >
            <span className="font-semibold">Contact Us</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};
