import React from 'react'
import security from "../../assets/service/data.svg";
import target from "../../assets/service/targeting.svg";
import cawi from "../../assets/service/cawi.png";
import robust from "../../assets/service/robust.svg";
import custom from "../../assets/service/customize.svg";
import expert from "../../assets/service/expert.svg";
import quick from "../../assets/service/quick.svg";
import support from "../../assets/service/responsive.svg";


function Computerassisted() {
  return (
    <div >
        <div className="w-full text-center mb-16 sm:mt-24 mt-4">
    <div
      className="w-11/12 inline-block text-center"
      id="computer-assisted"
    >
      <h1 className="font-bold text-4xl text-[#474747]">
        Computer-Assisted Web Interviewing (CATI, Online)
      </h1>
      <p className="mt-4">
        Trayistats AI Technologies is a comprehensive solution for global
        online data collection needs. It leverages a network of dedicated
        panel partners that have the capability to serve 40 locations and
        regions, reaching a substantial audience of 15 million active
        panel members.By collaborating with these panel partners,
        Trayistats AI Technologies gains access to valuable and engaged
        audiences. These audiences are extensively screened and
        meticulously selected to ensure their suitability for research
        purposes. This selection process helps to maintain the integrity
        and reliability of the collected data.
      </p>
      <p className="mt-4">
        Trayistats AI Technologies has a track record of successfully
        executing various global projects. These projects encompass a wide
        range of research areas, such as Trackers, Pre & Post Ad Tests,
        Concept Testing, Social Media Analysis, Conjoint Studies, Online
        Diaries, In-depth Online Surveys, Custom Panels, Customer &
        Employee Satisfaction Surveys, Behavioral Studies, and Mobile
        Studies, among others. This demonstrates the versatility and
        breadth of Trayistats AI Technologies capabilities in meeting
        diverse research needs.
      </p>
      <p className="mt-4">
        Notably, Trayistats AI Technologies Computer-Assisted Web
        Interviewing (CATI) service stands out as a unique offering. It is
        specifically designed to adapt to the ever-evolving modern world
        and its changing dynamics. This ensures that Trayistats AI
        Technologies remains at the forefront of data collection
        methodologies, utilizing innovative approaches to deliver reliable
        insights.
      </p>
      <p className="mt-4">
        Overall, Trayistats AI Technologies positions itself as a one-stop
        solution for global online data collection requirements, providing
        access to high-value, engaged audiences and offering a wide range
        of research services to address various needs.
      </p>
    </div>
  </div>
  <div className="w-full text-center mb-16 mt-16 bg-[#F5F5F5]">
    <div className="w-11/12 inline-block text-start">
      <div className="flex flex-col text-white w-full my-12">
        <div className="flex flex-col-reverse font-sans  items-center w-full gap-6 mx-auto md:flex-row md:gap-0">
          <div className="w-full md:w-full">
            <div className="">
              <p className="lg:w-3/4 md:w-full w-full leading-[2rem] md:mt-4 text-[2rem] md:text-[3rem] text-[#484848] font-semibold md:text-left">
                Features
              </p>
              <ul className="mt-8">
                <li>
                  <img src={expert} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Expert Panel Partnerships
                  </p>
                </li>
                <li>
                  <img src={security} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Data Security and Privacy
                  </p>
                </li>
                <li>
                  <img src={target} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Advanced Targeting Capabilities
                  </p>
                </li>
                <li>
                  <img src={quick} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Quick Turnaround Time
                  </p>
                </li>
                <li>
                  <img src={custom} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Customization and Scalability
                  </p>
                </li>
                <li>
                  <img src={robust} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Robust Data Quality Control
                  </p>
                </li>
                <li>
                  <img src={support} alt="" className="absolute" />
                  <p className="ml-10 mt-3 text-black">
                    Responsive Customer Support
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <img src={cawi} alt="" className="h-96 md:h-64" />
        </div>
      </div>
    </div>
  </div></div>
  )
}

export default Computerassisted