import React from 'react'
import whoweare from '../../assets/home/who-we-are.png'
import vision from '../../assets/home/vision.png'
import { Link } from 'react-router-dom'

function WhoWeAre() {
    return (
        <div className='bg-[#886CC0]'>
            <div className="w-full text-center">
                <div className="w-11/12 inline-block">
                    <div className="flex justify-center mt-5">
                        <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
                            <div className="md:w-1/2 flex justify-center">
                                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                                    <p className="text-4xl font-bold text-white pb-4 mt-10">WHO WE ARE ?</p>
                                    <p className="text-white">
                                    We take pride in our commitment to providing timely, cost-effective, and hassle-free research solutions. By partnering with clients across the globe, we ensure the utilization of optimal research methodologies to generate reliable and trustworthy data. Our unwavering dedication to meeting your research requirements instills confidence in the knowledge that your research needs are in capable hand
                                        </p>
                                    <div className="mt-8 text-center sm:text-left">
                                        <Link
                                            smooth
                                            to=""
                                            className="bg-[#ffffff] rounded-lg font-medium py-2 px-3 sm:px-5 hover:text-white hover:border-2 text-[#886CC0] text-xs sm:text-lg md:text-lg"
                                        >
                                            Learn more
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 flex justify-center">
                                <img src={whoweare} alt="who we are" className="mt-5 lg:h-80" />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center mt-5">
                        <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
                            <div className="md:w-1/2 flex justify-center">
                                <img src={vision} alt="work-content" className="mt-5 mb-10 lg:h-80" />
                            </div>
                            <div className="md:w-1/2 mt-4">
                                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start md:ml-10 mt-4">
                                    <h1 className="text-5xl font-bold text-white">Our Mission</h1>
                                    <p className="mt-4 text-white">To contribute to the advancement of the market research field through knowledge sharing, thought leadership, and participation in industry initiatives, ultimately elevating the standards and impact of market research practices.</p>
                                    <hr className='mt-4'></hr>
                                    <h1 className="text-5xl font-bold text-white mt-4">Vision</h1>
                                    <p className="mt-4 text-white mb-10">To foster a culture of innovation and excellence in market research, continually pushing the boundaries of knowledge and methodologies to deliver unparalleled value to our clients.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhoWeAre